import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useRef, useEffect } from "react";
/* eslint-disable import/no-cycle */
import Slider, { Settings } from "react-slick";

import clsx from "clsx";
import styles from "./carousel.module.sass";

export interface CarouselProps {
  slideWidth: number;
  children: React.ReactNode;
  settings: Settings;
  blurInactive?: boolean;
  onSlideChange?: () => void;
  beforeChange?: () => void;
}

const Carousel: React.FC<CarouselProps> = (props) => {
  const {
    // slideWidth,
    settings,
    children,
    blurInactive = true,
    onSlideChange,
    beforeChange,
  } = props;
  const numOfItems = React.Children.count(children);
  const ref = useRef(null);

  // const [maxVisibleItems, setMaxVisibleItems] = useState(0);
  // const [currentSlide, setCurrentSlide] = useState(0);
  const eventSetting: Settings = {
    afterChange: (/* indexOfCurrentSlide: number */) => {
      // setCurrentSlide(indexOfCurrentSlide);
      onSlideChange?.();
    },
    beforeChange() {
      beforeChange?.();
    },
  };
  useEffect(() => {
    if (document !== null) {
      const slickPrev = document.getElementsByClassName("slick-prev");
      const arrow = document.getElementsByClassName("arrow");
      const slickSlide = document.getElementsByClassName("slick-slide");
      for (let i = 0; i < slickSlide.length; i += 1) {
        slickSlide[i].removeAttribute("aria-hidden");
      }
      for (let i = 0; i < arrow.length; i += 1) {
        arrow[i].removeAttribute("aria-hidden");
      }
      for (let i = 0; i < slickPrev.length; i += 1) {
        slickPrev[i].setAttribute("aria-label", "Previous");
      }
      const slickNext = document.getElementsByClassName("slick-next");
      for (let i = 0; i < slickNext.length; i += 1) {
        slickNext[i].setAttribute("aria-label", "Next");
      }
    }
  }, []);
  /* useEffect(() => {
    const availableWidth = ref.current
      ? (ref.current as any)?.offsetWidth
      : window.innerWidth;
    setMaxVisibleItems(Math.floor(availableWidth / slideWidth));
  }, [slideWidth, currentSlide]); */

  return (
    <div>
      {numOfItems > 1 ? (
        <div ref={ref}>
          <Slider
            {...settings}
            {...eventSetting}
            className={clsx(styles.carousel, {
              [styles.blurInactive]: blurInactive,
            })}
          >
            {children}
          </Slider>
        </div>
      ) : (
        <div
          className={`${styles.noCarousel} is-flex is-justify-content-center`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Carousel;
