/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
import React, { FC } from "react";

import clsx from "clsx";
import { CardsTypes, TypeContentfulMap } from "Constants/variables";
import Skillbuilder from "components/Skillbuilder";
import BlackLeftArrow from "../../../public/static/images/homePage/carouselBlade/black_left_arrow.svg";
import BlackRightArrow from "../../../public/static/images/homePage/carouselBlade/black_right_arrow.svg";
import Button from "../Button";
import Carousel from "../Carousel";
import ChallengeCard from "../FeaturedChallenge";
import { ICarouselWrapperProps } from "./@types";
import ImageComponent from "../Image";
import LeftArrow from "../../../public/static/images/homePage/carouselBlade/left_arrow.svg";
import MediaNonFlipCard from "../MediaCards/MediaNonFlipCard";
import RightArrow from "../../../public/static/images/homePage/carouselBlade/right_arrow.svg";
import SectionWrapper from "../SectionWrapper";
import Typo from "../Typo";
import WinnerCard from "../WinnerCard";
import styles from "./carouselwrapper.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";

export const SliderArrow = React.forwardRef<
  HTMLButtonElement,
  {
    type: string;
    onClick: React.MouseEventHandler<HTMLSpanElement> | undefined;
    isCentered?: boolean;
  }
>((props, ref) => {
  const { type, onClick, isCentered } = props;
  let className =
    type === "next"
      ? isCentered
        ? "centeredNextArrow"
        : "nextArrow"
      : isCentered
        ? "centeredPrevArrow"
        : "prevArrow";
  className += " arrow";
  const char =
    type === "next" ? (
      <ImageComponent
        image={isCentered ? BlackRightArrow : RightArrow}
        width={isCentered ? 30 : 17}
        height={isCentered ? 30 : 17}
      />
    ) : (
      <ImageComponent
        image={isCentered ? BlackLeftArrow : LeftArrow}
        width={isCentered ? 30 : 17}
        height={isCentered ? 30 : 17}
      />
    );
  return (
    <button
      type="button"
      ref={ref}
      className={className}
      onClick={onClick}
      aria-hidden="true"
      style={{
        backgroundColor: "unset",
        border: "unset",
        borderRadius: "none",
        padding: "unset",
      }}
    >
      {char}
    </button>
  );
});

const CarouselWrapper: FC<ICarouselWrapperProps> = (props) => {
  const {
    cards,
    type,
    title,
    subtitle,
    description,
    topLink,
    bottomLink,
    hasCenteredArrows,
  } = props;
  const { isMobile } = useIsMobileViewport();
  const getSettings = () => {
    switch (type) {
      case CardsTypes.WINNER:
      case CardsTypes.SKILLBUILDER:
      case CardsTypes.CHALLENGE:
      case CardsTypes.IMAGE:
        return {
          dots: true,
          infinite: true,
          speed: 250,
          arrows: true,
          slidesToScroll: 1,
          variableWidth: true,
          centerMode: true,
          nextArrow: (
            <SliderArrow
              type="next"
              onClick={this}
              isCentered={hasCenteredArrows}
            />
          ),
          prevArrow: (
            <SliderArrow
              type="prev"
              onClick={this}
              isCentered={hasCenteredArrows}
            />
          ),
        };
      case CardsTypes.PARTNER:
        return {
          slidesToScroll: 3,
          speed: 250,
          centerMode: false,
          variableWidth: true,
          dots: false,
          arrows: false,
          infinite: true,
          slidesToShow: isMobile ? 3 : undefined,
        };
      default:
        return {};
    }
  };
  let slideWidth = 0;
  let headingTextColor;
  switch (type) {
    case CardsTypes.PARTNER:
      slideWidth = 106;
      headingTextColor = "is-color-Black";
      break;
    case CardsTypes.WINNER:
      slideWidth = isMobile ? 270 : 924;
      headingTextColor = "is-color-HighLightLime";
      break;
    case CardsTypes.CHALLENGE:
      slideWidth = isMobile ? 270 : 457;
      headingTextColor = "is-color-White";
      break;
    case CardsTypes.IMAGE:
      slideWidth = isMobile ? 270 : 475;
      headingTextColor = "is-color-Black";
      break;
    case CardsTypes.SKILLBUILDER:
      slideWidth = isMobile ? 270 : 924;
      headingTextColor = "is-color-Black";
      break;
    default:
      break;
  }

  const cardsNotMatchingType = cards.filter(
    (card) => card.sys.contentType.sys.id !== TypeContentfulMap[type],
  );

  if (cardsNotMatchingType.length > 0) {
    const notMatchingCards = cardsNotMatchingType
      .map((card: any) => card.fields.friendlyName)
      .join("\n - ");
    throw new Error(
      `Error in component: ${title}.\nSome cards are not of type ${type},\nCards not matching the type are: \n - ${notMatchingCards}`,
    );
  }

  return (
    <div>
      <SectionWrapper hasBodyMargin={false}>
        <div className={clsx(styles.root)}>
          <SectionWrapper>
            <header className={clsx(headingTextColor)}>
              {title && (
                <div
                  className={`${styles.title} is-flex is-justify-content-center has-text-centered mb-2`}
                >
                  <Typo h1>{title}</Typo>
                </div>
              )}
              {subtitle && (
                <div
                  className={`${styles.subtitle} is-flex is-justify-content-center`}
                >
                  <Typo h2>{subtitle}</Typo>
                </div>
              )}
              {description && (
                <Typo
                  medium
                  classes="is-flex is-justify-content-center mb-4 has-text-centered"
                >
                  {description}
                </Typo>
              )}
              {topLink && (
                <div
                  className={`${styles.topLink} is-flex is-justify-content-${
                    isMobile ? "center" : "flex-end"
                  } is-align-items-center`}
                >
                  <Button
                    isBtn={false}
                    link={topLink}
                    icon="arrowWhiteRight"
                    classes={`is-color-White ${styles.anchorTag}`}
                    iconClass="ml-3"
                  />
                </div>
              )}
            </header>
          </SectionWrapper>
          <Carousel
            slideWidth={slideWidth}
            settings={getSettings()}
            blurInactive={type !== CardsTypes.PARTNER}
          >
            {cards.map((card, index) => {
              switch (type) {
                case CardsTypes.PARTNER:
                  return (
                    <ImageComponent
                      key={index}
                      image={card.fields.image}
                      width={106}
                      height={58}
                      classes={styles.partnerImage}
                    />
                  );
                case CardsTypes.WINNER:
                  return <WinnerCard key={index} {...card.fields} />;
                case CardsTypes.CHALLENGE:
                  return <ChallengeCard key={index} {...card} />;
                case CardsTypes.IMAGE:
                  return (
                    <MediaNonFlipCard
                      key={index}
                      {...{
                        ...card,
                        descColor: "is-color-Black",
                        inCarousel: true,
                      }}
                    />
                  );
                case CardsTypes.SKILLBUILDER:
                  return <Skillbuilder key={index} {...card} />;
                default:
                  return <div />;
              }
            })}
          </Carousel>
          {bottomLink && (
            <div
              className={`${styles.bottomLink} is-flex is-justify-content-center`}
            >
              <Button link={bottomLink} />
            </div>
          )}
        </div>
      </SectionWrapper>
    </div>
  );
};

export default CarouselWrapper;
