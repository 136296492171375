import { FC } from "react";
import Link from "next/link";
import { Block } from "@contentful/rich-text-types";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import helpers from "../../utils/helpers";
import Button from "../Button";
import { CType } from "../../models/Contentful/types";
import { IChallengeProps } from "../../models/Challenge/@types";
import ImageComponent from "../Image";
import { ROUTES } from "../../Constants/variables";
import Typo from "../Typo";
import VideoPlayer from "../VideoPlayer";
import { getChallengeUrl } from "../../models/Challenge";
import styles from "./challengeCardHorizontal.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";

const ChallengeCardHorizontal: FC<CType<IChallengeProps>> = (props) => {
  const { fields } = props;
  const {
    title,
    description,
    media,
    image,
    videoUrl,
    industryList,
    duration,
    partners,
    slug,
    skills,
  } = fields;
  const { isMobile } = useIsMobileViewport();
  const industry = industryList?.fields?.title?.join(", ");
  const charactersAllowed = isMobile ? 60 : 86;
  const descriptionText = documentToPlainTextString(
    description as unknown as Block,
  );
  let descriptionUptoLimit = descriptionText;
  if (descriptionText.length > charactersAllowed) {
    descriptionUptoLimit = descriptionText.substring(0, charactersAllowed);
  }

  const challengeUrl = getChallengeUrl(fields);
  const withLink = (child: React.ReactNode) => (
    <Link href={challengeUrl}>{child}</Link>
  );

  return (
    <div className={styles.challengeCard}>
      <div className={styles.cardContainer}>
        <div className={styles.imageAndPartner}>
          <div className={styles.media}>
            {media === "Video" && videoUrl ? (
              <VideoPlayer
                videoUrl={videoUrl}
                classes={styles.videoPlayer}
                aspectRatio="16 / 9"
              />
            ) : (
              <ImageComponent
                image={image}
                height={isMobile ? "146px" : "195px"}
                width="100%"
                aspectRatio="9/16"
                crop
              />
            )}
          </div>
          <div className={styles.challengePartners}>
            {partners?.map((partner, index) => {
              return (
                index < 3 && (
                  <ImageComponent
                    image={partner.fields.image}
                    key={Math.random()}
                    width={isMobile ? "85px" : "100px"}
                    height="30px"
                  />
                )
              );
            })}
          </div>
        </div>
        {/* INFO PART */}
        <div className={styles.infoContainer}>
          {/* Text part */}
          <div className={`${styles.challengeInfo}`}>
            {withLink(
              <Typo
                h3
                limitLines={3}
                classes={`mb-3 is-color-Blue ${styles.title}`}
              >
                {title}
              </Typo>,
            )}

            {descriptionText && (
              <div className={styles.lineClamp}>
                <Typo
                  normal
                  limitLines={2}
                  classes={`is-color-Black ${styles.description}`}
                >
                  {descriptionUptoLimit}
                  {descriptionUptoLimit.length < descriptionText?.length &&
                    withLink(
                      <Typo normal classes="is-color-Blue">
                        ...Learn more
                      </Typo>,
                    )}
                </Typo>
              </div>
            )}
          </div>
          {/* Points part */}
          <div className={styles.challengeDurationAndIndustry}>
            <Typo small>{`${duration} Challenge`}</Typo>
            <Typo small>{industry}</Typo>
          </div>
          <div className="is-flex is-justify-content-space-between">
            {skills.fields.title.slice(0, 3).map((skill) => (
              <Typo small classes="is-color-BlackHalf mr-1" key={skill}>
                {skill}
              </Typo>
            ))}
          </div>
        </div>
        <Button
          theme="Filled - Lime"
          classes={styles.challengeButton}
          linkToUrl={helpers.generatePath(ROUTES.CHALLENGE, {
            slug,
          })}
        >
          View Challenge
        </Button>
      </div>
    </div>
  );
};

export default ChallengeCardHorizontal;
