import React, { FC } from "react";
import clsx from "clsx";

import { CType } from "../../models/Contentful/types";
import Carousel from "../../components/Carousel";
import { ResourceBladeProps } from "./@types";
import ResourceCard from "../../components/Resource/ResourceCard/ResourceCard";
import SectionWrapper from "../../components/SectionWrapper";
import Typo from "../../components/Typo";
import { logger } from "../../utils";
import styles from "./resources.module.sass";
import { useIsTabletViewport } from "../../hooks/useIsMobileViewport";

const Resource: FC<CType<ResourceBladeProps>> = (props) => {
  try {
    const {
      fields: { ...data },
    } = props;

    const backColor = (data as any)?.commonProperties?.fields
      ?.parentBackgroundColor;

    const textColor =
      backColor === "Purple" ||
      backColor === "Blue" ||
      backColor === "Black" ||
      backColor === "Red" ||
      backColor === "BlackHalf" ||
      backColor === "DarkBlue" ||
      backColor === "HighLightLimeLight"
        ? "has-text-white"
        : "";

    return (
      <div className={`${styles.mainContainer} ${textColor}`}>
        <Typo h2 classes={`has-text-centered ${styles.heading}`}>
          {data.for}
        </Typo>
        <Wrapper color={textColor}>
          {data.resource.map((m, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className={`${styles.cardWidth}`}>
              <ResourceCard key={m.sys?.id} {...m} />
            </div>
          ))}
        </Wrapper>
      </div>
    );
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export default Resource;

const Wrapper: FC<{ children: React.ReactNode; color: string }> = ({
  children,
  color,
}) => {
  const { isTablet } = useIsTabletViewport();
  return isTablet ? (
    <div
      className={clsx(styles.carouselContainer, {
        [styles.carouselDots]: color !== "has-text-white",
      })}
    >
      <Carousel
        slideWidth={270}
        settings={{
          dots: true,
          infinite: true,
          speed: 250,
          arrows: false,
          slidesToScroll: 1,
          variableWidth: true,
          centerMode: true,
          swipeToSlide: true,
        }}
      >
        {children}
      </Carousel>
    </div>
  ) : (
    <SectionWrapper>
      <div className={styles.gridContainer}>{children}</div>
    </SectionWrapper>
  );
};
