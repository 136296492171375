/* eslint-disable import/no-cycle */
import { logger } from "../../utils";
import FeatureText from "../../components/FeatureText";
import { CType } from "../../models/Contentful/types";
import { IFeatureTextProps } from "./@types";

const FeatureTextBlade: React.FC<CType<IFeatureTextProps>> = (props) => {
  try {
    const {
      fields: { ...data },
    } = props;

    return <FeatureText {...data} />;
  } catch (error) {
    logger.error(error);
    return null;
  }
};
export default FeatureTextBlade;
