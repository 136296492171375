/* eslint-disable prettier/prettier */
import React, { FC } from "react";
import NoSsr from "components/NoSsr";
import Typo from "components/Typo";
import helpers, { DEFAULT_DATE_FORMAT } from "utils/helpers";

export interface IDateTimeRendererProps {
  date: string;
  dateFormat?: string;
  typoProps?: any;
  typoClasses?: string;
  textBefore?: string;
  textAfter?: string;
}

const DateTimeRenderer: FC<IDateTimeRendererProps> = (props) => {
  const {
    typoProps,
    typoClasses,
    textBefore = "",
    textAfter = "",
    date,
    dateFormat = DEFAULT_DATE_FORMAT,
  } = props;
  return (
    <div style={{ marginTop: "-6.5px" }}>
      <NoSsr>
        <Typo {...typoProps} classes={typoClasses}>
          {`${textBefore} ${helpers.dateString(
            date,
            dateFormat
          )} ${textAfter} `}
        </Typo>
      </NoSsr>
    </div>
  );
};

export default DateTimeRenderer;
