/* eslint-disable import/no-named-default */
/* eslint-disable react/no-array-index-key */
import clsx from "clsx";
import React, { FC } from "react";
import Link from "../../components/Link";
import RichText from "../../components/RichText";
import Button, { ThemeType } from "../../components/Button";
import { logger } from "../../utils";
import SectionWrapper from "../../components/SectionWrapper";
import Typo from "../../components/Typo";
import { useIsTabletViewport } from "../../hooks/useIsMobileViewport";
import { CType } from "../../models/Contentful/types";
import { IHeaderDetailProps } from "./@types";
import { default as styles } from "./header.module.sass";

interface IHeaderPropsWithCss extends CType<IHeaderDetailProps> {
  customCss?: boolean;
}

const MainBladeDetails: FC<IHeaderPropsWithCss> = (props) => {
  const { isTablet } = useIsTabletViewport();
  try {
    const {
      fields: { ...data },
    } = props;
    const { customCss } = props;
    return (
      <>
        <SectionWrapper
          hasBodyMargin={!isTablet}
          childClasses={clsx(styles.headingContainer, "header-container")}
        >
          {data.heading && (
            <Typo h1 classes={`${styles.title} has-text-centered`}>
              {data.heading}
            </Typo>
          )}
          {data.description && (
            <div
              className={`${styles.description} ${
                customCss && styles.descriptionWidth
              }`}
            >
              <RichText document={data.description} />
            </div>
          )}
          <div className={`${styles.buttonLinksContainer}`}>
            {data.buttonLinks && data.buttonLinks.length > 0 && (
              <div
                className={`is-flex is-justify-content-space-evenly ${styles.buttonLinks}`}
              >
                {data.buttonLinks.map((link, index) => {
                  const isBtn = !!link.fields.layout;
                  return (
                    <Button
                      isBtn={isBtn}
                      theme={link.fields.layout as ThemeType}
                      link={link}
                      key={index}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </SectionWrapper>
        {data.links?.length && (
          <SectionWrapper
            hasBodyMargin={!isTablet}
            classes={styles.linkContainer}
            childClasses={
              isTablet ? "is-block" : "is-flex is-justify-content-space-evenly"
            }
          >
            {data.links.map((link) => {
              return (
                <div key={link.sys?.id} className={styles.linkTabs}>
                  <Link {...link} />
                </div>
              );
            })}
          </SectionWrapper>
        )}
      </>
    );
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export default MainBladeDetails;
