/* eslint-disable import/no-cycle */
import React, { FC } from "react";
import dynamic from "next/dynamic";
import { logger } from "../../utils";
import Button, { ThemeType } from "../../components/Button";
import ImageComponent from "../../components/Image";
import { CType } from "../../models/Contentful/types";
import { ContributeBladeProps } from "./@types";
import styles from "./contributeBlade.module.sass";
import SectionWrapper from "../../components/SectionWrapper";
import Typo from "../../components/Typo";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";

const GridLines = dynamic(() => import("../../components/Icons/GridLines"), {
  ssr: false,
});

export const fallbackUrl =
  "https://images.unsplash.com/photo-1449156733864-dd5471bb7427?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80";

const ContributeBlade: FC<CType<ContributeBladeProps>> = (props) => {
  const { isMobile } = useIsMobileViewport();
  try {
    const {
      fields: { ...data },
    } = props;
    const backgroundColor = data?.commonProperties?.fields?.backgroundColor;

    return (
      <div className={`is-relative ${styles.rootContainer}`}>
        <GridLines className={styles.imgContainer} />
        <div className={isMobile ? "py-5" : "py-6"}>
          <SectionWrapper
            hasBodyMargin={false}
            parentBackgroundColor={backgroundColor}
            classes={isMobile ? "mx-3" : "mx-auto"}
            childClasses={`${isMobile ? "is-block" : "is-flex"} ${
              styles.contentContainer
            }`}
          >
            <ImageComponent
              image={data.image ?? fallbackUrl}
              aspectRatio="1.54/1"
              classes={styles.sideImg}
              height={isMobile ? "auto" : "195px"}
              width={isMobile ? "100%" : "300px"}
            />
            <div>
              {data?.title && (
                <Typo h3 classes="mb-4">
                  {data.title}
                </Typo>
              )}
              {data?.description && (
                <Typo normal classes="mb-5 is-block">
                  {data.description}
                </Typo>
              )}
              {data.link && (
                <div className="is-flex  is-justify-content-center">
                  <Button
                    link={data.link}
                    theme={data.link.fields.layout as ThemeType}
                  />
                </div>
              )}
            </div>
          </SectionWrapper>
        </div>
      </div>
    );
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export default ContributeBlade;
