import { Document } from "@contentful/rich-text-types";
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-cycle */
import { FC } from "react";
import ImageComponent from "../Image";
import RichText from "../RichText";
import SectionWrapper from "../SectionWrapper";
import { SingleRowContentProps } from "../../blades/singleRowContent/@types";
import Typo from "../Typo";
import styles from "./singleRow.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";

export interface SingleRowProps extends SingleRowContentProps {
  backgroundColor: string;
  titleColor: string;
  descColor: string;
}
export interface TextComponentProps {
  title: string;
  description: Document;
  titleColor: string;
  descColor: string;
  isMobile: boolean;
}

const TextComponent: FC<TextComponentProps> = (props) => {
  const { title, description, titleColor, descColor, isMobile } = props;
  return (
    <div className={`is-flex is-flex-direction-column ${styles.textComponent}`}>
      <Typo h2 classes={`is-color-${titleColor} mb-2`}>
        {title}
      </Typo>
      <Typo
        medium={!isMobile}
        big={isMobile}
        classes={`is-color-${descColor} `}
      >
        <RichText
          document={description}
          classes={`rich-text-container ${styles.wordBreak}`}
        />
      </Typo>
    </div>
  );
};

const SingleRow: FC<SingleRowProps> = (props) => {
  const {
    title,
    description,
    image,
    imagePosition,
    backgroundColor,
    titleColor,
    descColor,
  } = props;
  const { isMobile } = useIsMobileViewport();

  return (
    <SectionWrapper classes={`${styles.root} `}>
      {imagePosition === "Left" && (
        <div
          className={`is-flex is-justify-content-space-between is-bgcolor-${backgroundColor} ${
            backgroundColor === "White" ? styles.bodyWhite : styles.body
          } ${isMobile && "is-flex-direction-column"}`}
        >
          <div>
            <ImageComponent
              image={image}
              // height={`${isMobile ? 166 : 195}`}
              width={`${isMobile ? "220px" : "300px"}`}
              classes={styles.imageBorder}
            />
          </div>
          <TextComponent
            {...{ title, titleColor, descColor, description, isMobile }}
          />
        </div>
      )}
      {imagePosition === "Right" && (
        <div
          className={`is-flex is-bgcolor-${backgroundColor} ${
            backgroundColor === "White" ? styles.bodyWhite : styles.body
          } ${isMobile && "is-flex-direction-column"} `}
        >
          <TextComponent
            {...{ title, titleColor, descColor, description, isMobile }}
          />
          <div>
            <ImageComponent
              image={image}
              width={`${isMobile ? "220px" : "300px"}`}
              // height={`${isMobile ? 166 : 195}`}
              classes={styles.imageBorder}
              // aspectRatio="16/9"
            />
          </div>
        </div>
      )}
      {imagePosition !== "Right" && imagePosition !== "Left" && (
        <div
          className={`is-flex is-bgcolor-${backgroundColor} ${
            backgroundColor === "White" ? styles.bodyWhite : styles.body
          } has-text-centered`}
        >
          <TextComponent
            {...{ title, titleColor, descColor, description, isMobile }}
          />
        </div>
      )}
    </SectionWrapper>
  );
};

export default SingleRow;
