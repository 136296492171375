/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-cycle */
import { FC } from "react";
import clsx from "clsx";

import RichTextBlade, { IRichTextBladeProps } from "../../blades/RichText";
import { CType } from "../../models/Contentful/types";
import Carousel from "../Carousel";
import { IPartnersProps } from "../../blades/partnersComponent/@types";
import SectionWrapper from "../SectionWrapper";
import Typo from "../Typo";
import styles from "./partnersColumnComponent.module.sass";
import { useIsTabletViewport } from "../../hooks/useIsMobileViewport";

const PartnersColumnComponent: FC<IPartnersProps> = (props) => {
  const { heading, partnersList } = props;
  const { isTablet } = useIsTabletViewport();
  return (
    <SectionWrapper classes={`has-text-centered ${styles.wrapper}`}>
      {heading && (
        <Typo h2 classes="mb-5">
          {heading}
        </Typo>
      )}
      {isTablet ? (
        <CarouselBlade partnersList={partnersList} />
      ) : (
        <div className={styles.partnersName}>
          {partnersList.map((partnerList) => (
            <div>
              <Typo normal>
                <RichTextBlade {...partnerList} />
              </Typo>
            </div>
          ))}
        </div>
      )}
    </SectionWrapper>
  );
};

export default PartnersColumnComponent;

const CarouselBlade: FC<{ partnersList: CType<IRichTextBladeProps>[] }> = ({
  partnersList,
}) => {
  return (
    <div className={clsx(styles.carouselContainer)}>
      <Carousel
        slideWidth={270}
        settings={{
          dots: true,
          infinite: true,
          speed: 250,
          arrows: false,
          slidesToScroll: 1,
          variableWidth: true,
          centerMode: true,
          swipeToSlide: true,
        }}
      >
        {partnersList.map((partnerList) => (
          <div className={styles.partnerColumnContainer}>
            <Typo normal>
              <RichTextBlade {...partnerList} />
            </Typo>
          </div>
        ))}
      </Carousel>
    </div>
  );
};
