/* eslint-disable import/no-cycle */
import React, { FC } from "react";
import shortid from "shortid";
import SectionWrapper from "../components/SectionWrapper";
import WidgetMapping from "./Mapping";
import { IWidgetProps } from "./widgetTypes";

const Widgets: FC<IWidgetProps> = ({ widget = [], customCss = false }) => {
  const componentPromises = widget?.map((widget) => {
    const View = WidgetMapping[widget.sys?.contentType?.sys?.id];
    if (!View) return <div key={shortid.generate()} />;
    const properties = (widget.fields as any)?.commonProperties;
    return (
      <SectionWrapper
        key={shortid.generate()}
        hasBodyMargin={false}
        backgroundColor={properties?.fields?.backgroundColor}
        parentBackgroundColor={properties?.fields?.parentBackgroundColor}
        backgroundImage={properties?.fields?.backgroundImage}
        hasImageBlur={!!properties?.fields?.backgroundImage}
        isDark={!!properties?.fields?.isDark}
      >
        {customCss ? (
          <View key={shortid.generate()} {...widget} customCss />
        ) : (
          <View key={shortid.generate()} {...widget} />
        )}
      </SectionWrapper>
    );
  });
  return <>{componentPromises} </>;
};

export default Widgets;
