import React, { FC } from "react";

import { IListHeaderProps } from "./@types";
import RichText from "../RichText";
import SectionWrapper from "../SectionWrapper";
import Typo from "../Typo";
import styles from "./list.module.sass";

const Header: FC<IListHeaderProps> = ({ title, description }) => {
  if (!title && !description) return null;
  return (
    <div className={styles.headerRoot}>
      <SectionWrapper>
        <div className={styles.headerContent}>
          {title && <Typo h2>{title}</Typo>}{" "}
          {description && <RichText document={description} />}
        </div>
      </SectionWrapper>
    </div>
  );
};

export default Header;
