/* eslint-disable import/no-cycle */
import { logger } from "../../utils";
import TabsContent from "../../components/TabsContent/TabsContent";
import { CType } from "../../models/Contentful/types";

const TabContents: React.FC<CType<any>> = (props) => {
  try {
    const {
      fields: { ...data },
    } = props;
    return <TabsContent {...data} />;
  } catch (error) {
    logger.error(error);
    return null;
  }
};
export default TabContents;
