/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable prettier/prettier */
import { FC } from "react";
import clsx from "clsx";
import Button from "../Button";
import { CType } from "../../models/Contentful/types";
import { IEventCardProps } from "./@types";
import Icons from "../SvgLib/icons";
import Link from "../Link";
import SectionWrapper from "../SectionWrapper";
import Typo from "../Typo";
import styles from "./eventCard.module.sass";
import DateTimeRenderer from "../DateTime/dateTime";

const DESCRIPTION_MAX_CHARACTERS = 80;

const EventCard: FC<CType<IEventCardProps>> = (props) => {
  const {
    fields: {
      title,
      description = "",
      dedicatedTo,
      date,
      duration,
      presentedBy,
      category,
      links,
      backgroundColor,
    },
  } = props;
  let descriptionUptoLimit = description;
  if (description?.length > DESCRIPTION_MAX_CHARACTERS) {
    descriptionUptoLimit = description.substring(0, DESCRIPTION_MAX_CHARACTERS);
  }

  return (
    <SectionWrapper
      hasBodyMargin={false}
      classes={clsx(styles.eventCardParentContainer, {
        [styles.backgroundGreen]: backgroundColor === "HighLightGreen",
      })}
    >
      <SectionWrapper>
        <div className={styles.eventCard}>
          {dedicatedTo && (
            <div className={styles.badge}>
              <Typo>For {dedicatedTo}</Typo>
            </div>
          )}
          <div className={styles.categoryContainer}>
            <Typo normal classes="category">
              [ {category} ]
            </Typo>
          </div>

          <div className={styles.eventContentContainer}>
            <div className={styles.ImageContainer}>{Icons({}).calender}</div>
            <div className={styles.col2}>
              <Link {...links}>
                <Typo h3 classes="is-color-Blue">
                  {title}
                </Typo>
              </Link>
              <div className={styles.subHeading}>
                <DateTimeRenderer
                  date={date}
                  dateFormat="MMM DD, YYYY hh:mm A"
                  typoProps={{ small: true }}
                />
                <Typo small classes={styles.contentSpace}>
                  {duration}
                </Typo>
                {presentedBy && <Typo small>Presented by {presentedBy}</Typo>}
              </div>
              <div>
                <Typo normal limitLines={3}>
                  {descriptionUptoLimit}
                  {descriptionUptoLimit?.length < description?.length && (
                    <Link {...links}>
                      <Typo medium classes="is-color-Blue">
                        ...Learn more
                      </Typo>
                    </Link>
                  )}
                </Typo>
              </div>
            </div>
            <div className={styles.col3}>
              <Button theme="Filled - Lime" link={links} />
            </div>
          </div>
        </div>
      </SectionWrapper>
    </SectionWrapper>
  );
};

export default EventCard;
