/* eslint-disable import/no-cycle */
import { logger } from "../../utils";
import Hero from "../../components/Hero";
import { CType } from "../../models/Contentful/types";
import { IHeroBladeProps } from "./@types";

const HeroBlade: React.FC<CType<IHeroBladeProps>> = (props) => {
  try {
    const {
      fields: { ...data },
    } = props;

    return <Hero {...data} />;
  } catch (error) {
    logger.error(error);
    return null;
  }
};
export default HeroBlade;
