import { CType } from "../Contentful/types";
import UserModel from "../User";
import { logger } from "../../utils";
import { IChallengeProps, IChallengeTab } from "./@types";

export const ChallengeTabs: IChallengeTab[] = [
  {
    name: "Overview",
    slug: "overview",
    widgetKey: "overview",
  },
  {
    name: "Explore",
    slug: "explore",
    widgetKey: "explore",
  },
  {
    name: "Focus",
    slug: "focus",
    widgetKey: "focus",
  },
  {
    name: "Imagine",
    slug: "imagine",
    widgetKey: "imagine",
  },
  {
    name: "Create",
    slug: "create",
    widgetKey: "create",
  },
  {
    name: "Pitch",
    slug: "pitch",
    widgetKey: "pitch",
  },
];

export const getChallengeBaseUrl = (challenge: IChallengeProps) => {
  return `challenges/${challenge.slug}`;
};
export const getChallengeUrl = (
  challenge: IChallengeProps,
  tab?: IChallengeTab,
) => {
  return `/${getChallengeBaseUrl(challenge)}/${
    tab?.slug ?? ChallengeTabs[0].slug
  }`;
};

export const getChallengeUrlFromSlug = (
  challengeSlug: string,
  tab?: IChallengeTab,
) => {
  return `challenges/${challengeSlug}/${tab?.slug ?? ChallengeTabs[0].slug}`;
};

export const getChallengeDetailsForUser = async (
  challengeIds: string[],
  challenges: CType<IChallengeProps>[],
) => {
  try {
    const { data } = await UserModel.getChallengesInfoForUser(challengeIds);
    const newChallenge = challenges.map((cData) => {
      const newData = {
        ...cData,
        fields: {
          ...cData.fields,
          ...data.find((data) => data.challenge_id === cData.sys?.id),
        },
      };
      return { ...newData };
    });
    return newChallenge;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export default {};
