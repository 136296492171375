/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import { FC } from "react";
import clsx from "clsx";
import dynamic from "next/dynamic";

import Button, { ThemeType } from "../Button";
import ChallengeCard from "../FeaturedChallenge";
import { IFeaturedContentBladeProps } from "../../blades/featuredContentBlade/@types";
import ImageComponent from "../Image";
import RichText from "../RichText";
import SectionWrapper from "../SectionWrapper";
import Skillbuilder from "../Skillbuilder";
import Typo from "../Typo";
import styles from "./featuredContent.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";

const GridLines = dynamic(() => import("../Icons/GridLines"), {
  ssr: false,
});

const Header: FC<IFeaturedContentBladeProps> = ({
  heading,
  subHeading,
  description,
  type,
}) => {
  const textColor = type === "Challenge" ? "is-color-White" : "is-color-Black";
  return (
    <div className={`${styles.bladeText}`}>
      {heading && (
        <Typo h2 classes={`${styles.mainHeading} ${textColor}`}>
          {heading}
        </Typo>
      )}
      {subHeading && (
        <Typo h3 classes={clsx(textColor, "mb-4")}>
          {subHeading}
        </Typo>
      )}
      {description && (
        <Typo normal classes={clsx(textColor)}>
          <RichText document={description} />
        </Typo>
      )}
    </div>
  );
};

const ChallengeContainer: FC<
  IFeaturedContentBladeProps & { isMobile: boolean }
> = ({ isMobile, card }) => {
  return (
    <>
      <div className={`${styles.artistContainer}`}>
        <div>
          <ImageComponent
            image="/static/images/homePage/featuredContent/fill_blue_circle.svg"
            width={isMobile ? "130px" : "223px"}
            height={isMobile ? "130px" : "223px"}
            classes={`${styles.fillCircle}`}
          />
          <ImageComponent
            image="/static/images/homePage/featuredContent/circle_green.svg"
            width={isMobile ? "60px" : "145px"}
            height={isMobile ? "60px" : "145px"}
            classes={`${styles.borderCircle}`}
          />
          <ImageComponent
            image="/static/images/homePage/heroBlade/circlePoints.svg"
            width={isMobile ? "57px" : "146px"}
            height={isMobile ? "57px" : "146px"}
            classes={`${styles.pointedCircle}`}
          />
        </div>
      </div>
      <ChallengeCard {...card} />
    </>
  );
};

const FeaturedContent: FC<IFeaturedContentBladeProps> = (props) => {
  const { isMobile } = useIsMobileViewport();
  const { card, type, link, commonProperties } = props;
  return (
    <SectionWrapper hasBodyMargin={false}>
      {type === "Skillbuilder" && (
        <GridLines className={styles.gridBackground} />
      )}
      <SectionWrapper
        hasBodyMargin={!isMobile}
        parentBackgroundColor={commonProperties?.fields?.backgroundColor}
        childClasses={clsx("has-text-centered", "py-6", {
          [styles.challengeContainer]: type === "Challenge" && !isMobile,
        })}
      >
        <Header {...props} />
        <div
          className={clsx(styles.contentCard, {
            [styles.contentCardChallenge]: type === "Challenge",
          })}
        >
          {type === "Challenge" ? (
            <ChallengeContainer {...props} isMobile={isMobile} />
          ) : (
            <Skillbuilder {...card} />
          )}
        </div>
        {link && (
          <Button
            theme={link.fields.layout as ThemeType}
            link={link}
            key={0}
            classes={`${styles.button}`}
          />
        )}
      </SectionWrapper>
    </SectionWrapper>
  );
};

export default FeaturedContent;
