/* eslint-disable react/no-array-index-key */
import React, { FC } from "react";
import Head from "next/head";
import SvgLib from "../SvgLib";
import { ILoadMoreButtonProps } from "./@types";

const LoadMoreButton: FC<ILoadMoreButtonProps> = ({
  hasMore = true,
  loadMore,
  meta,
}) => {
  if (!hasMore) return null;
  const { currentPage } = meta; // Math.ceil((meta.fetchedCount ?? 1) / meta.pageSize);
  const pathName = window.location.pathname;
  const totalPage = Math.ceil(meta.total / meta.pageSize);
  let previousPage: string | undefined = `${pathName}?page=${currentPage - 1}`;
  if (currentPage === 1) previousPage = undefined;
  if (currentPage === 2) previousPage = pathName;

  const nextPage =
    currentPage < totalPage ? `${pathName}?page=${currentPage + 1}` : undefined;
  return (
    <div className="has-text-centered mb-5">
      <a
        className="button is-responsive is-align-self-center is-flex-direction-column is-eventLink is-onlyText"
        href={nextPage}
        onClick={(e) => {
          e.preventDefault();
          loadMore();
        }}
      >
        Load More
        <SvgLib icon="arrow-down" />
      </a>

      <Head>
        {previousPage ? <link rel="prev" href={previousPage} /> : null}
        {nextPage ? <link rel="next" href={nextPage} /> : null}
      </Head>

      <noscript>
        {previousPage ? <a href={previousPage}>Prev</a> : null}
        {nextPage ? <a href={nextPage}>Next</a> : null}
        {[...new Array(totalPage)].map((item, index) => {
          const page = index + 1;
          if (page === currentPage) return <span key={index}>currentPage</span>;
          return (
            <a key={index} href={`${pathName}?page=${page}`}>
              {page}
            </a>
          );
        })}
      </noscript>
    </div>
  );
};

export default LoadMoreButton;
