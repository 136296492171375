import { ApiResponse, TList } from "../@types";
import { CType } from "../Contentful/types";
import UserModel, { TSkillBuilderSubmission } from "../User";
import { logger, request } from "../../utils";
import {
  ISkillbuilderProps,
  ISkillbuilderWinner,
  TSkillbuilderSubmissionAndProps,
} from "./@types";

export const createSkillbuilderSubmission = async (
  skillbuilderId: string,
  submission: string,
  score: number,
): Promise<ApiResponse<TSkillBuilderSubmission>> => {
  return request<ApiResponse<TSkillBuilderSubmission>>({
    url: `skillbuilder/${skillbuilderId}/submit`,
    method: "POST",
    data: { submission, score },
  });
};

export const hasUserAlreadyParticipated = async (
  skillbuilderId: string,
): Promise<ApiResponse<boolean>> => {
  return request<ApiResponse<boolean>>({
    url: `skillbuilder/${skillbuilderId}/hasUserParticipated`,
    method: "GET",
  });
};

export const getSkillbuilderDetailsForUser = async (
  skillbuilderIds: string[],
  skillbuilders: CType<ISkillbuilderProps>[],
) => {
  try {
    const { data } =
      await UserModel.getSkillbuilderInfoForUser(skillbuilderIds);
    const newSkillbuilder = skillbuilders.map((sData) => {
      const newData = {
        ...sData,
        fields: {
          ...sData.fields,
          ...data.find(
            (data) =>
              data.skillbuilder_id === sData?.sys?.id ?? sData.fields.slug,
          ),
        },
      };
      return { ...newData };
    });
    return newSkillbuilder;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const getSkillbuilderDetailsForStudent = async (userId: string) => {
  try {
    const { data } = await UserModel.getSkillbuilderInfoForStudent(userId);
    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const getSkillbuilders = async (
  customSearchSort?: any,
  skip = 0,
  limit = 2,
): Promise<ApiResponse<TList<TSkillbuilderSubmissionAndProps>>> => {
  return request<ApiResponse<TList<TSkillbuilderSubmissionAndProps>>>({
    url: `/skillbuilders`,
    params: {
      limit,
      skip,
      ...customSearchSort,
    },
  });
};

export const generateSkillbuilderCsv = async (params: any) => {
  const response = await request<BlobPart>({
    url: `/skillbuilder/get-csv`,
    responseType: "blob",
    params,
  });
  return new Blob([response]);
};

export const changeWinnerStatus = async (
  id: string,
  winner: number | undefined,
  winnerType: boolean,
) => {
  return request<ApiResponse<ISkillbuilderWinner>>({
    url: `update_skillbuilder_winner`,
    method: "POST",
    data: { id, winner, winnerType },
  });
};
