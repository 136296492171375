import React, { FC } from "react";
import ContactUs from "../../components/ContactUs";
import { CType } from "../../models/Contentful/types";
import { logger } from "../../utils";
import { IContactUsBlade } from "./@types";

const ContactUsBlade: FC<CType<IContactUsBlade>> = (props) => {
  try {
    const {
      fields: { ...data },
    } = props;
    return <ContactUs {...data} />;
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export default ContactUsBlade;
