/* eslint-disable import/no-cycle */
import React, { FC } from "react";
import Image from "next/image";
import dynamic from "next/dynamic";

import ARROW from "../../../public/static/images/skillbuilder/arrow.svg";
import CIRCLE_OUTLINED from "../../../public/static/images/skillbuilder/circle_outlined_green.svg";
import FULL_CIRCLE_FILLED from "../../../public/static/images/skillbuilder/full_circle_filled_green.svg";
import RichText from "../RichText";
import STAR_CIRCLE_OUTLINED from "../../../public/static/images/skillbuilder/star_circle_outlined_blue.svg";
import SectionWrapper from "../SectionWrapper";
import Skillbuilder from "../Skillbuilder";
import Typo from "../Typo/index";
import { skillbuilderHeroProps } from "../../blades/skillbuilderHeroBlade/@types";
import utils from "../../utils";
import { useIsTabletViewport } from "../../hooks/useIsMobileViewport";
import styles from "./skillbuilderFinal.module.sass";

const GridLines = dynamic(() => import("../Icons/GridLines"), {
  ssr: false,
});

const SkillbuilderHeroFinal: React.FC<skillbuilderHeroProps> = (props) => {
  const { isTablet } = useIsTabletViewport();
  const { title, description, skillbuilderCard, footerText } = props;
  return (
    <div>
      <SectionWrapper>
        <div className={styles.root}>
          <div className={styles.heading}>
            <Typo h1>{title}</Typo>
            <div style={{ marginTop: isTablet ? 8 : 32 }} />
            <Typo medium classes="has-text-left">
              <RichText document={description} />
            </Typo>
            <div style={{ flex: 1 }} />
            <div className="is-relative is-align-self-center">
              <Typo h2 classes={`has-text-white ${styles.footerText}`}>
                {footerText}
              </Typo>
              {!isTablet && (
                <Img
                  src={ARROW}
                  alt="arrow"
                  width="100"
                  height="100"
                  positionProperties={{
                    top: "10%",
                    right: "-50%",
                  }}
                />
              )}
            </div>
          </div>
          <div className={styles.cardContainer}>
            <Img
              src={CIRCLE_OUTLINED}
              alt="circle outlined"
              width={isTablet ? 99 : 160}
              height={isTablet ? 99 : 160}
              positionProperties={{
                bottom: isTablet ? -50 : -38,
                left: isTablet ? -15 : -48,
              }}
            />
            <Img
              src={STAR_CIRCLE_OUTLINED}
              alt="circle outlined"
              width={isTablet ? 104 : 215}
              height={isTablet ? 104 : 215}
              positionProperties={{
                bottom: isTablet ? 21 : 25,
                left: isTablet ? -25 : -45,
              }}
            />
            <Skillbuilder {...skillbuilderCard} />
          </div>
          <Img
            src={FULL_CIRCLE_FILLED}
            alt="circle outlined"
            width={isTablet ? 107 : 107}
            height={isTablet ? 73 : 107}
            positionProperties={{
              bottom: isTablet ? "50%" : "10%",
              left: isTablet ? -60 : -175,
            }}
          />
        </div>
      </SectionWrapper>
      <GridLines className={styles.imgContainer} height="50%" />
    </div>
  );
};

export default SkillbuilderHeroFinal;

interface ImgProps {
  src: string;
  alt?: string;
  width: number | `${number}`;
  height: number | `${number}`;
  positionProperties: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  className?: string;
}

const Img: FC<ImgProps> = (props) => {
  const { className, width, height, positionProperties, src, alt } = props;
  return (
    <div
      className={className}
      style={{ position: "absolute", ...positionProperties }}
    >
      <Image
        src={src}
        alt={alt || "image default text"}
        width={width}
        height={height}
        loader={utils.imageLoader}
        // style={{
        //   maxWidth: "100%",
        //   height: "auto",
        // }}
      />
    </div>
  );
};
