import {
  CHALLENGE_WINNERS,
  CHALLENGE_WINNER_SLUG,
  SKILLBUILDER_WINNERS,
  SKILLBUILDER_WINNER_SLUG,
} from "../../components/WinnerCard/@types";
import { IWinnerTab } from "./@types";

export const WinnersTabs: IWinnerTab[] = [
  {
    name: CHALLENGE_WINNERS,
    slug: CHALLENGE_WINNER_SLUG,
  },
  {
    name: SKILLBUILDER_WINNERS,
    slug: SKILLBUILDER_WINNER_SLUG,
  },
];

export default {};
