/* eslint-disable import/no-cycle */
import { logger } from "../../utils";
import TabsComponent from "../../components/Tabs";
import { CType } from "../../models/Contentful/types";
import { TabsBladeProps } from "./@types";

const TabsBlade: React.FC<CType<TabsBladeProps>> = (props) => {
  try {
    const {
      fields: { ...data },
    } = props;
    return <TabsComponent {...data} />;
  } catch (error) {
    logger.error(error);
    return null;
  }
};
export default TabsBlade;
