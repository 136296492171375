/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-cycle */
import { FC, useState } from "react";

import { CType } from "../../../models/Contentful/types";
import { IMediaCardsProps } from "../../../blades/multiColumnBlade/@types";
import ImageComponent from "../../Image";
import ModalWrapper from "../../ModalWrapper";
import RichText from "../../RichText";
import SvgLib from "../../SvgLib";
import Typo from "../../Typo";
import VideoPlayer from "../../VideoPlayer";
import styles from "./nonFlipCard.module.sass";
import { useIsMobileViewport } from "../../../hooks/useIsMobileViewport";

export interface MediaCardsProps extends CType<IMediaCardsProps> {
  descColor: string;
  inCarousel?: boolean;
}

const MediaNonFlipCard: FC<MediaCardsProps> = (props) => {
  const { fields, descColor, inCarousel = false } = props;
  const { description, videoUrl, media, thumbnail, videoHeight, videoWidth } =
    fields;
  const [isActive, setIsActive] = useState(false);
  const { isMobile } = useIsMobileViewport();
  return (
    <div
      className={`${styles.videoPlayer} ${inCarousel && styles.carouselCard}`}
    >
      {!videoUrl ? (
        <div className={`${styles.imageComponent}`}>
          <a onClick={() => setIsActive(true)} aria-hidden="true">
            <ImageComponent
              image={media}
              aspectRatio="16/9"
              // width="100%"
              // height="auto"
              classes="mb-3"
            />
          </a>
          <ModalWrapper
            isActive={isActive}
            onClose={() => setIsActive(false)}
            hasCloseButton
            closeBtnClasses={styles.circularCloseBtn}
          >
            <div className={styles.imageModalWrapper}>
              <ImageComponent
                image={media}
                aspectRatio="16/9"
                width="100%"
                height="auto"
                classes="mb-3"
              />
              {description && (
                <Typo
                  medium={!isMobile}
                  big={isMobile}
                  classes={`is-color-${descColor} ${styles.richTextPara} `}
                >
                  <RichText document={description} />
                </Typo>
              )}
            </div>
          </ModalWrapper>
        </div>
      ) : (
        <div className={`${styles.setPosition} `}>
          <a
            onClick={() => setIsActive(true)}
            aria-hidden="true"
            className={styles.thumbnailContainer}
          >
            <ImageComponent
              image={thumbnail}
              aspectRatio="16 / 9"
              width="100%"
              height="auto"
              classes="mb-3"
            >
              <SvgLib icon="playVideo" classes={styles.playIcon} />
            </ImageComponent>
          </a>
          <ModalWrapper
            isActive={isActive}
            onClose={() => setIsActive(false)}
            hasCloseButton
            closeBtnClasses={styles.circularCloseBtn}
          >
            <VideoPlayer
              videoUrl={videoUrl}
              videoHeight={videoHeight}
              videoWidth={videoWidth}
              isActive={isActive}
            />
          </ModalWrapper>
        </div>
      )}
      {description && (
        <Typo
          medium={!isMobile}
          big={isMobile}
          classes={`is-color-${descColor} ${styles.richTextPara} `}
        >
          <RichText document={description} />
        </Typo>
      )}
    </div>
  );
};

export default MediaNonFlipCard;
