import React from "react";
import clsx from "clsx";
import { IListProps } from "./@types";
import styles from "./list.module.sass";

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const ContentList = <T extends unknown>({
  items = [],
  keyExtractor,
  renderItem,
  layout = "grid",
}: IListProps<T>) => {
  return (
    <div className="is-flex is-flex-direction-column is-align-items-center mb-6">
      <div
        className={clsx({
          [styles.grid]: layout === "grid",
          [styles.list]: layout === "list",
        })}
      >
        {items.map((item, index) => (
          <div
            className={clsx(styles.item, "list-item")}
            key={keyExtractor(item)}
          >
            {renderItem(item, index)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContentList;
