/* eslint-disable import/no-cycle */
// import SkillbuilderHero from "../../components/SkillbuilderHero";
import { logger } from "../../utils";
import SkillbuilderHeroFinal from "../../components/SkillbuilderHeroFinal";
import { CType } from "../../models/Contentful/types";
import { skillbuilderHeroProps } from "./@types";

const SkillbuilderHeroBlade: React.FC<CType<skillbuilderHeroProps>> = (
  props,
) => {
  try {
    const {
      fields: { ...data },
    } = props;
    return <SkillbuilderHeroFinal {...data} />;
  } catch (error) {
    logger.error(error);
    return null;
  }
};
export default SkillbuilderHeroBlade;
