import dynamic from "next/dynamic";
import React, { FC } from "react";

const NoSsr: FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div>{children}</div>;
};

export default dynamic(() => Promise.resolve(NoSsr), {
  ssr: false,
});
