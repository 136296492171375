import { Theme, Theming } from "../../models/Theme/@types";

const ThemeDefinition = (theme: Theme) => {
  let theming: Theming;
  switch (theme) {
    case "DarkBlue":
      theming = "DarkBlue-HighLightGreen-White";
      break;
    case "White":
      theming = "White-Black-Black";
      break;
    case "Blue":
      theming = "Blue-White-White";
      break;
    case "Purple":
      theming = "Purple-HighLightGreen-White";
      break;
    case "Grey":
      theming = "Grey-Black-Black";
      break;
    default:
      theming = "White-Black-Black";
      break;
  }
  const themeFormat = theming.split("-");
  const backgroundColor = themeFormat[0];
  const titleColor = themeFormat[1];
  const descColor = themeFormat[2];
  return { backgroundColor, titleColor, descColor };
};

export default ThemeDefinition;
