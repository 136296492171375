import React, { FC } from "react";

import { CType } from "../../models/Contentful/types";
import InternalLink from "../../components/Link/InternalLink";
import { MainBladeDetailsProps } from "./@types";
import SectionWrapper from "../../components/SectionWrapper";
import Typo from "../../components/Typo";
import { logger } from "../../utils";
import styles from "./mainBladeDetails.module.sass";
import { useIsTabletViewport } from "../../hooks/useIsMobileViewport";

const MainBladeDetails: FC<CType<MainBladeDetailsProps>> = (props) => {
  const { isTablet } = useIsTabletViewport();
  try {
    const {
      fields: { ...data },
    } = props;
    return (
      <>
        <SectionWrapper
          hasBodyMargin={!isTablet}
          childClasses={`${styles.headingContainer}`}
        >
          {data.heading && (
            <Typo h1 classes={`${styles.title} has-text-centered`}>
              {data.heading}
            </Typo>
          )}
          {data.description && (
            // <span
            //   className={`${styles.description} has-text-centered is-size-8 is-family-primary`}
            // >
            //   {data.description}
            // </span>
            <Typo medium classes={`${styles.description} has-text-centered`}>
              {data.description}
            </Typo>
          )}
        </SectionWrapper>
        {data.links?.length && (
          <SectionWrapper
            hasBodyMargin={!isTablet}
            classes={styles.linkContainer}
            childClasses={
              isTablet ? "is-block" : "is-flex is-justify-content-space-evenly"
            }
          >
            {data.links.map((link) => {
              const {
                fields: { ...data },
              } = link;
              if (!data.referencePage) return null;
              return (
                <div key={link.sys?.id} className={styles.linkTabs}>
                  <InternalLink referencePage={data.referencePage}>
                    {/* {data.text && (
                  <InternalLink referencePage={data.referencePage}>
                    {data.text && (
                      <Typo classes="is-underlined">{data.text}</Typo>
                    )} */}
                    {data.text && data.text}
                  </InternalLink>
                </div>
              );
            })}
          </SectionWrapper>
        )}
      </>
    );
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export default MainBladeDetails;
