/* eslint-disable import/no-cycle */
import SortableComponent from "../../components/SortableComponent";
import { logger } from "../../utils";
import { CType } from "../../models/Contentful/types";
import { SortableBladeProps } from "./@types";

const SortableBlade: React.FC<CType<SortableBladeProps>> = (props) => {
  try {
    const {
      fields: { ...data },
    } = props;
    return <SortableComponent {...data} />;
  } catch (error) {
    logger.error(error);
    return null;
  }
};
export default SortableBlade;
