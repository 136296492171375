/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-cycle */
import { FC } from "react";
import Link from "next/link";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { Block } from "@contentful/rich-text-types";
import Button from "../Button";
import { CType } from "../../models/Contentful/types/core";
import { ISkillbuilderProps } from "../../models/SkillBuilder/@types";
import ImageComponent from "../Image";
import SectionWrapper from "../SectionWrapper";
import Typo from "../Typo";
import styles from "./skillbuilder.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";

const SkillbuilderCard: FC<ISkillbuilderProps> = (props) => {
  const { isMobile } = useIsMobileViewport();
  const {
    title,
    description,
    // image,
    skillIcon,
    skills,
    industryList,
    award,
    earnPoints,
    duration,
    partners,
    slug,
    status,
  } = props;

  const industry = industryList?.fields?.title?.join(", ");
  const skillNames = skills?.fields?.title ?? [];
  const charactersAllowed = isMobile ? 60 : 86;
  const descriptionText = documentToPlainTextString(
    description as unknown as Block,
  );
  let descriptionUptoLimit = descriptionText;
  if (descriptionText.length > charactersAllowed) {
    descriptionUptoLimit = descriptionText.substring(0, charactersAllowed);
  }

  const topImage = () => {
    return (
      <div className={styles.topImage}>
        <ImageComponent
          image={skillIcon}
          width={isMobile ? "100px" : "130px"}
          height={isMobile ? "90px" : "120px"}
        />
      </div>
    );
  };
  const withLink = (child: React.ReactNode) => (
    <Link href={`/skillbuilders/${slug}`}>{child}</Link>
  );
  return (
    <div>
      <div className={styles.cardRoot}>
        <div className="is-flex is-flex-direction-column">
          <div
            className={`${styles.topSection} is-flex is-justify-content-space-between`}
          >
            <div className="is-flex is-flex-direction-column mt-1">
              <Typo small>{industry}</Typo>
              <Typo small>{skillNames[0]}</Typo>
            </div>
            {partners && (
              <div>
                {partners.map((partner, index) => {
                  return (
                    index < 1 && (
                      <ImageComponent
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        image={partner.fields.image}
                        // width="68px"
                        height="37px"
                      />
                    )
                  );
                })}
              </div>
            )}
          </div>
          {topImage()}
          <div className="px-5">
            {withLink(
              <Typo h3 limitLines={2} classes={`${styles.title} mt-3`}>
                {title}
              </Typo>,
            )}

            <Typo small classes="is-color-BlackHalf mt-2">
              Effort: {duration}
            </Typo>
            {descriptionText && (
              <div className={`has-text-centered ${styles.lineClamp}`}>
                <Typo
                  normal
                  limitLines={2}
                  classes={`is-color-Black ${styles.description}`}
                >
                  {`${descriptionUptoLimit}`}
                  {descriptionUptoLimit.length < descriptionText.length &&
                    withLink(
                      <Typo normal classes="is-color-Blue">
                        ...Read More
                      </Typo>,
                    )}
                </Typo>
              </div>
            )}
          </div>
          <div className="is-flex is-justify-content-center mt-4">
            <Typo normal classes={styles.pointsGrid}>
              Earns: <br />
              {earnPoints} points
            </Typo>
            {award && (
              <Typo normal classes={styles.pointsGrid}>
                Awarded: <br />
                {award}
              </Typo>
            )}
          </div>
          <div className="my-4">
            {withLink(
              <Button
                theme="Filled - Lime"
                disabled={status === "completed"}
                // style={{
                //   width: isMobile ? 60 : 91,
                //   height: isMobile ? 38 : 46,
                // }}
              >
                {status === "completed" ? "Completed" : "Do It!"}
              </Button>,
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Skillbuilder: FC<CType<ISkillbuilderProps>> = (props) => {
  const { fields } = props;
  return (
    // <SectionWrapper hasBodyMargin={!!isMobile} classes={styles.root}>
    <SectionWrapper hasBodyMargin={false} classes={styles.root}>
      <SkillbuilderCard {...fields} />
    </SectionWrapper>
  );
};

export default Skillbuilder;
