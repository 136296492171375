/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-cycle */
import { FC } from "react";
import Link from "next/link";
import router from "next/router";
import { Block } from "@contentful/rich-text-types";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import helpers, { DATE_TIME_FORMAT } from "../../utils/helpers";
import Button from "../Button";
import { CType } from "../../models/Contentful/types";
import { IChallengeProps } from "../../models/Challenge/@types";
import ImageComponent from "../Image";
import { ROUTES } from "../../Constants/variables";
import SvgLib from "../SvgLib";
import Typo from "../Typo";
import VideoPlayer from "../VideoPlayer";
import { getChallengeUrl } from "../../models/Challenge";
import styles from "./featuredChallenge.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";
import DateTimeRenderer from "../DateTime/dateTime";

const ChallengeCard: FC<CType<IChallengeProps>> = (props) => {
  const { fields } = props;
  const {
    title,
    description,
    media,
    image,
    videoUrl,
    // skillName,
    industryList,
    earnPoints,
    award,
    dueDate,
    duration,
    partners,
    launchDate,
    status,
    project_id,
  } = fields;
  const { isMobile } = useIsMobileViewport();
  const industry = industryList?.fields?.title?.join(", ");
  const charactersAllowed = isMobile ? 60 : 86;
  const descriptionText = documentToPlainTextString(
    description as unknown as Block,
  );
  const linkToProject = helpers.generatePath(ROUTES.PROJECT, {
    projectId: project_id as string,
  });
  let descriptionUptoLimit = descriptionText;
  if (descriptionText.length > charactersAllowed) {
    descriptionUptoLimit = descriptionText.substring(0, charactersAllowed);
  }

  const challengeUrl = getChallengeUrl(fields);
  const withLink = (child: React.ReactNode) => (
    <Link href={challengeUrl}>{child}</Link>
  );

  return (
    <div
      className={`${styles.challengeCard} has-text-centered is-flex is-flex-direction-column`}
    >
      {launchDate && new Date(launchDate) > new Date() && (
        <div className={`is-bgcolor-HighLightLime ${styles.launchType}`}>
          <Typo small>Coming Soon</Typo>
        </div>
      )}

      {media === "Video" && videoUrl ? (
        <VideoPlayer
          videoUrl={videoUrl}
          classes={styles.videoPlayer}
          aspectRatio="16 / 9"
        />
      ) : (
        <ImageComponent image={image} height="193px" aspectRatio="16 / 9" />
      )}
      <div
        className={`${styles.challengePartners} is-flex is-justify-content-space-evenly`}
      >
        {partners?.map((partner, index) => {
          return (
            index < 3 && (
              <ImageComponent
                image={partner.fields.image}
                key={Math.random()}
                width={isMobile ? "85px" : "100px"}
                height="30px"
              />
            )
          );
        })}
      </div>
      {/* INFO PART */}
      <div>
        {/* Text part */}
        <div className={`${styles.challengeInfo}`}>
          {withLink(
            <Typo
              h2
              limitLines={3}
              classes={`mb-3 is-color-Blue is-size-3 ${styles.title}`}
            >
              {title}
            </Typo>,
          )}

          {descriptionText && (
            <div className={`has-text-centered ${styles.lineClamp}`}>
              <Typo
                normal
                limitLines={2}
                classes={`is-color-Black ${styles.description}`}
              >
                {`${descriptionUptoLimit}`}
                {descriptionUptoLimit.length < descriptionText?.length &&
                  withLink(
                    <Typo normal classes="is-color-Blue">
                      ...Read More
                    </Typo>,
                  )}
              </Typo>
            </div>
          )}
        </div>
        {/* Points part */}
        <div
          className={`is-flex ${
            isMobile ? " is-flex-direction-column" : "is-flex-direction-row"
          } has-text-left  is-color-Black ${styles.challengePoints} `}
        >
          <div
            className={`is-flex ${
              isMobile ? " is-flex-direction-row" : "is-flex-direction-column"
            } `}
          >
            <div className="is-flex is-align-items-center">
              <Typo normal>Earns</Typo>
            </div>
            <div className="is-flex is-align-items-center mt-1">
              <SvgLib icon="earned" iconSize="Medium" />
              <Typo h3>{`+${earnPoints} Points`}</Typo>
            </div>
          </div>
          {award && isMobile && <div className={`${styles.borderLine}`} />}
          {award && (
            <div
              className={`is-flex ${
                isMobile
                  ? " is-flex-direction-row "
                  : "is-flex-direction-column"
              } `}
            >
              <div className="is-flex is-align-items-center">
                <Typo normal>Winner</Typo>
              </div>
              <div className="is-flex is-align-items-center mt-1">
                <SvgLib icon="winner" iconSize="Medium" />
                <Typo h3 classes="ml-2">
                  {award}
                </Typo>
              </div>
            </div>
          )}
        </div>
      </div>
      {isMobile ? (
        <div className="is-color-BlackHalf">
          <div>
            <Typo small>{industry}</Typo>
          </div>
          <div className="is-flex is-flex-direction-row has-text-left is-justify-content-space-between py-2 px-4">
            <Typo small>{`${duration} Challenge`}</Typo>
            <DateTimeRenderer
              date={dueDate}
              dateFormat={DATE_TIME_FORMAT}
              typoProps={{ small: true }}
              textBefore="Due: "
            />
          </div>
        </div>
      ) : (
        <div className="is-flex is-flex-direction-row has-text-left is-justify-content-space-between is-color-BlackHalf py-4 px-4">
          <Typo small>{`${duration} Challenge`}</Typo>
          <Typo small>{industry}</Typo>

          <DateTimeRenderer
            date={dueDate}
            textBefore="Due: "
            typoProps={{ small: true }}
          />
        </div>
      )}
      {status === "active" && (
        <Button
          theme="Filled - Lime"
          classes={styles.projectButton}
          onClick={() => status === "active" && router.push(linkToProject)}
        >
          Active: Go To Project
        </Button>
      )}
    </div>
  );
};

export default ChallengeCard;
