/* eslint-disable import/no-cycle */
import { logger } from "../../utils";
import ThemeDefinition from "../../utils/Theme";
import MultiColumnComponent from "../../components/MultiColumns";
import { CType } from "../../models/Contentful/types";
import { IMultiColumnBladeProps } from "./@types";

const MultiColumn: React.FC<CType<IMultiColumnBladeProps>> = (props) => {
  try {
    const {
      fields: { ...data },
    } = props;
    const { backgroundColor, titleColor, descColor } = ThemeDefinition(
      data.theme,
    );

    return (
      <MultiColumnComponent
        {...{ titleColor, descColor, backgroundColor, ...data }}
      />
    );
  } catch (error) {
    logger.error(error);
    return null;
  }
};
export default MultiColumn;
