import React, { useEffect, useState } from "react";

import Button from "../Button";
import styles from "./scrollButton.module.sass";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const toggleVisible = () => {
      const yOffSet = window.pageYOffset;
      yOffSet > 500 ? setVisible(true) : setVisible(false);
    };
    window.addEventListener("scroll", toggleVisible);
    return function cleanupListener() {
      window.removeEventListener("scroll", toggleVisible);
    };
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Button
      ariaLabel="scroll to top"
      onClick={scrollToTop}
      classes={`${styles.scrollButton} py-4 ${visible ? "" : "is-invisible"} `}
      icon="arrow-up"
    />
  );
};

export default ScrollButton;
