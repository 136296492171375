import React, { FC } from "react";
import clsx from "clsx";
import { Document } from "@contentful/rich-text-types";
import RichText from "../../components/RichText";
import { CType } from "../../models/Contentful/types";
import { logger } from "../../utils";

export interface IRichTextBladeProps {
  text: Document;
  isCenterAligned?: boolean;
}
const RichTextBlade: FC<CType<IRichTextBladeProps>> = ({
  fields: { text, isCenterAligned },
}) => {
  try {
    return (
      <div className={clsx({ "has-text-centered": isCenterAligned })}>
        <RichText document={text} />
      </div>
    );
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export default RichTextBlade;
