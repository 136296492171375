import { FC, useContext, useEffect, useState } from "react";
import AuthContext from "../../AppContext/AuthProvider";
import ContentList from "../ContentList";
import { IContentListProps } from "../ContentList/@types";
import FilterBar from "../ContentList/FilterBar";
import Header from "../ContentList/header";
import LoadMoreButton from "../ContentList/LoadMoreButton";
import useContentList from "../ContentList/useContentList";
import ChallengeCard from "../FeaturedChallenge";
import ScrollButton from "../ScrollButton";
import SectionWrapper from "../SectionWrapper";
import { getChallengeDetailsForUser } from "../../models/Challenge";
import { IChallengeProps } from "../../models/Challenge/@types";
import { getTagList } from "../../models/Contentful";
import { CType } from "../../models/Contentful/types";

export type TChallengeEntry = CType<IChallengeProps>;
const ChallengeList: FC<IContentListProps> = (props) => {
  const { title, description } = props;
  const [industries, setIndustries] = useState<string[]>([]);
  const [mutatedChallenge, setMutatedChallenge] = useState<TChallengeEntry[]>(
    [],
  );
  const {
    loadMore,
    hasMore,
    list: challenges,
    applyFilter,
    total,
    currentPage,
  } = useContentList<IChallengeProps>({
    contentType: "challenge",
    filter: {
      // order: "sys.updatedAt",
      order: "-fields.launchDate" || "sys.updatedAt",
    },
  });
  const { appUser } = useContext(AuthContext);
  useEffect(() => {
    getTagList("industries").then((result) => setIndustries(result ?? []));
  }, []);

  useEffect(() => {
    const mutateChallengeData = async () => {
      const challengesIdList = challenges.map((item) => {
        return item.sys?.id ?? item.fields?.slug;
      });

      const newChallenge = await getChallengeDetailsForUser(
        challengesIdList,
        challenges,
      );

      setMutatedChallenge(newChallenge);
    };
    appUser && mutateChallengeData();
  }, [appUser, challenges]);

  return (
    <SectionWrapper hasBodyMargin={false}>
      <Header title={title} description={description} />

      <FilterBar
        primary={[
          {
            key: "industryList.fields.title",
            list: industries,
            label: "View All Industries",
          },
        ]}
        onChange={(key: string, value?: string) => {
          applyFilter({
            [`fields.${key}`]: value ?? "",
            "fields.industryList.sys.contentType.sys.id": "industries",
          });
        }}
      />
      <SectionWrapper>
        <ContentList<TChallengeEntry>
          items={mutatedChallenge?.length ? mutatedChallenge : challenges}
          keyExtractor={(challenge: TChallengeEntry) =>
            (challenge.sys?.id ?? "") as string
          }
          renderItem={(item: TChallengeEntry) => <ChallengeCard {...item} />}
        />
      </SectionWrapper>
      <SectionWrapper>
        <LoadMoreButton
          hasMore={!!challenges?.length && hasMore}
          loadMore={loadMore}
          meta={{
            total,
            fetchedCount: challenges.length,
            pageSize: 4,
            currentPage,
          }}
        />
      </SectionWrapper>
      <ScrollButton />
    </SectionWrapper>
  );
};

export default ChallengeList;
