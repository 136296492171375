import React, { FC } from "react";
import Image from "next/image";
import {
  useIsMobileViewport,
  useIsTabletViewport,
} from "../../../hooks/useIsMobileViewport";
import utils, { logger } from "../../../utils";

import { CResourceMedia } from "../../../blades/resources/@types";
import IMAGE_ICON from "../Icons/Image.svg";
import PDF_ICON from "../Icons/PDF.svg";
import Typo from "../../Typo";
import styles from "./resourceCard.module.sass";

const fallbackUrl =
  "https://images.unsplash.com/photo-1560719887-fe3105fa1e55?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80";

const ResourceCard: FC<CResourceMedia> = (props) => {
  const { isTablet } = useIsTabletViewport();
  const { isMobile } = useIsMobileViewport();
  try {
    const {
      fields: { ...data },
    } = props;
    const thumbnailUrl = data?.thumbnail?.fields?.file?.url;
    const iconUrl = getFileImage(data?.media?.fields?.file?.contentType);
    const resourceUrl =
      data?.mediaUrlForResources || data?.media?.fields?.file?.url;
    return (
      <a
        href={resourceUrl}
        target="_blank"
        download={data.title}
        rel="noreferrer"
        className={styles.downlodLink}
      >
        <div
          className={`${styles.cardContainer} ${
            !thumbnailUrl && iconUrl ? styles.cardIcon : ""
          }`}
        >
          <Image
            src={thumbnailUrl ?? iconUrl ?? fallbackUrl}
            alt={data.title}
            loader={utils.imageLoader}
            className={`${styles.cardImg} `}
            width={isTablet ? "50" : "100"}
            height={isTablet ? "50" : "100"}
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
          {/* {data.description && (
            <Typo classes={styles.cardDescription}>
              <RichText document={data.description} />
            </Typo>
          )} */}
          {data.title && (
            <Typo
              medium={!isMobile}
              big={isMobile}
              classes={`${styles.cardDescription} has-text-centered`}
            >
              {data.title}
            </Typo>
          )}
        </div>
      </a>
    );
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export default ResourceCard;

enum FileType {
  PDF = "application/pdf",
}

const getFileImage = (fileType?: string): string | undefined => {
  const ImageRegExp = /^(image\/)\w+/g;
  if (!fileType) return undefined;
  if (fileType === FileType.PDF) return PDF_ICON;
  if (ImageRegExp.test(fileType)) return IMAGE_ICON;
  return undefined;
};
