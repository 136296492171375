/* eslint-disable import/no-cycle */
import { ICarouselWrapperProps } from "../../components/CarouselWrapper/@types";
import { logger } from "../../utils";
import CarouselWrapper from "../../components/CarouselWrapper";
import { CType } from "../../models/Contentful/types";

const CarouselBlade: React.FC<CType<ICarouselWrapperProps>> = (props) => {
  try {
    const {
      fields: { ...data },
    } = props;
    return <CarouselWrapper {...data} />;
  } catch (error) {
    logger.error(error);
    return null;
  }
};
export default CarouselBlade;
