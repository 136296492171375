/* eslint-disable prettier/prettier */
/* eslint-disable react/no-array-index-key */
import React, { FC, useEffect, useState } from "react";
import Link from "next/link";
import clsx from "clsx";
import ClickAwayListener from "react-click-away-listener";

import Button, { IButtonProps, ThemeType } from "../Button";
import { SelectFieldOption } from "../FormFields/SelectField";
import helpers from "../../utils/helpers";
import styles from "./dropdownWrapper.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";

export interface DropdownWrapperProps {
  title: string;
  items: string[] | Array<SelectFieldOption>;
  selectedItem: string | number | undefined;
  setSelectedItem: (value?: string) => void;
  showIcon?: boolean;
  showClearOption?: boolean;
  isFullWidth?: boolean;
  isLinked?: boolean;
  headingButtonTheme?: ThemeType;
  closeOnSelect?: boolean;
}

const DropdownWrapper: FC<DropdownWrapperProps> = (props) => {
  const {
    items,
    title,
    selectedItem,
    setSelectedItem,
    showIcon,
    showClearOption = true,
    isFullWidth = false,
    headingButtonTheme,
    isLinked = false,
    closeOnSelect = true,
  } = props;
  const [isActive, setIsActive] = useState(false);
  const { isMobile } = useIsMobileViewport();
  const iconProps: IButtonProps = showIcon
    ? {
        icon: "arrow-down",
        iconPos: "right",
        iconSize: "Medium",
      }
    : {};

  const [options, setOptions] = useState<SelectFieldOption[]>([]);
  useEffect(() => {
    if (typeof items?.[0] === "string") {
      setOptions(
        items.map((i) => ({ id: i, name: i, value: i })) as SelectFieldOption[]
      );
    } else setOptions(items as SelectFieldOption[]);
  }, [items]);

  const onItemSelect = (item: SelectFieldOption) => {
    if (typeof item.value === "string") {
      setSelectedItem(item.value);
      if (closeOnSelect) setIsActive(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setIsActive(false)}>
      <div
        className={clsx(
          "dropdown",
          styles.dropdown,
          `${isActive ? "is-active" : ""}`,
          isFullWidth ? styles.fullWidth : ""
        )}
      >
        <div className={`dropdown-trigger ${!isMobile && "has-text-left"}`}>
          <Button
            eventLink
            onlyText
            underline
            onClick={() => setIsActive(!isActive)}
            theme={headingButtonTheme || "Transparent - Black Border"}
            {...iconProps}
          >
            {selectedItem || title}
          </Button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className={`dropdown-content ${styles.dropdownContainer}`}>
            {options.map((item, index) => {
              return (
                <Button
                  role="menuitem"
                  key={index}
                  isBtn={false}
                  onClick={() => onItemSelect(item)}
                  className={`dropdown-item ${styles.dropdownItem} is-bgcolor-Grey`}
                  style={{
                    paddingRight: "none",
                    textAlign: isMobile ? "center" : "left",
                    cursor: "pointer",
                  }}
                >
                  {isLinked ? (
                    <Link
                      href={`#${typeof item.value === "string" ? helpers.slugStringify(item.value) : ""}`}
                      legacyBehavior
                    >
                      {item.name}
                    </Link>
                  ) : (
                    item.name
                  )}
                </Button>
              );
            })}
            {showClearOption && (
              <div className="dropdown-item">
                <Button eventLink onlyText onClick={() => setSelectedItem("")}>
                  Clear
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default DropdownWrapper;
