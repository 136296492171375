/* eslint-disable react/no-array-index-key */
import { FC } from "react";
import { Document } from "@contentful/rich-text-types";
import {
  IFeatureGridBladeProps,
  IFeatureGridCardProps,
} from "../../blades/featureGridBlade/@types";

import { CType } from "../../models/Contentful/types";
import Button, { ThemeType } from "../Button";
import ImageComponent from "../Image";
import RichText from "../RichText";
import SectionWrapper from "../SectionWrapper";
import Typo from "../Typo";
import styles from "./featureGrid.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";

const FeatureGridCard: FC<CType<IFeatureGridCardProps>> = (props) => {
  const { isMobile } = useIsMobileViewport();
  const { fields } = props;
  const { title, description, image, links } = fields;
  return (
    <div className={styles.featureCardContainer}>
      <ImageComponent
        image={image}
        width={`${isMobile ? "268px" : "300px"}`}
        height={`${isMobile ? "200px" : "240px"}`}
        classes="mt-6 mb-5"
      />

      <Typo h3 classes={`${isMobile ? `mt-4 mb-2` : `mb-4`} ${styles.title}`}>
        {title}
      </Typo>
      <Typo
        {...(isMobile ? { small: true } : { normal: true })}
        classes={`${styles.description} mb-4`}
      >
        <RichText document={description as unknown as Document} />
      </Typo>
      {links && links.length > 0 && (
        <div className={styles.button}>
          {links.map((link, index) => {
            const isBtn = !!link.fields.layout;
            return (
              <Button
                isBtn={isBtn}
                theme={link.fields.layout as ThemeType}
                link={link}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const FeatureGrid: FC<IFeatureGridBladeProps> = (props) => {
  const { heading, cards } = props;
  const cardsLen = cards.length;
  const { isMobile } = useIsMobileViewport();
  return (
    <>
      <SectionWrapper
        classes={`${styles.sectionWrapper}`}
        hasBodyMargin={false}
      >
        <div className={`${styles.featureGridContainer} has-text-centered`}>
          <Typo h2>{heading}</Typo>
        </div>
        {!isMobile && <div className={styles.hr} />}
      </SectionWrapper>

      {cards?.map((card, index) => (
        <div key={index}>
          {index % 2 === 0 && (
            <SectionWrapper key={index}>
              <div
                className={`${
                  !isMobile
                    ? `${styles.featureGrid} is-flex is-flex-direction-row`
                    : "is-flex is-flex-direction-column"
                }`}
              >
                <FeatureGridCard {...card} />
                {cardsLen - 1 > index && (
                  <FeatureGridCard {...cards[index + 1]} key={index + 1} />
                )}
              </div>
            </SectionWrapper>
          )}
          {index % 2 !== 0 && index > 1 && !isMobile && (
            <SectionWrapper hasBodyMargin={false} key={index}>
              <div className={styles.hr} />
            </SectionWrapper>
          )}
        </div>
      ))}
      {!isMobile && (
        <SectionWrapper hasBodyMargin={false}>
          <div className={styles.hr} />
        </SectionWrapper>
      )}
    </>
  );
};

export default FeatureGrid;
