/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-default */
import { FC } from "react";
import clsx from "clsx";
import { IMultiColumnBladeProps } from "../../blades/multiColumnBlade/@types";
import MediaFlipCard from "../MediaCards/MediaFlipCard";
import MediaNonFlipCard from "../MediaCards/MediaNonFlipCard";
import RichText from "../RichText";
import SectionWrapper from "../SectionWrapper";
import Typo from "../Typo";
import { default as styles } from "./multiColumn.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";

export interface MultiColumnBladeProps extends IMultiColumnBladeProps {
  backgroundColor: string;
  titleColor: string;
  descColor: string;
}

const MultiColumnComponent: FC<MultiColumnBladeProps> = (props) => {
  const {
    title,
    description,
    mediaCards,
    backgroundColor,
    titleColor,
    descColor,
    numberOfColumn,
  } = props;
  const { isMobile } = useIsMobileViewport();
  const titleEmpty = title?.replaceAll(" ", "") === "";
  return (
    <SectionWrapper
      classes={`has-text-centered ${styles.wrapper} is-bgcolor-${backgroundColor} `}
    >
      {title && !titleEmpty && (
        <Typo h2 classes={`is-color-${titleColor}`}>
          {title}
        </Typo>
      )}
      <div className={`is-color-${descColor} my-4`}>
        <Typo medium={!isMobile} big={isMobile}>
          <RichText document={description} />
        </Typo>
      </div>
      <div
        className={clsx(styles.cardGrid, {
          [styles.fourColumn]: numberOfColumn === 4,
        })}
      >
        {mediaCards.map((mediaCard, index) => {
          return !mediaCard.fields.isFlipCard ? (
            // eslint-disable-next-line react/no-array-index-key
            <MediaNonFlipCard key={index} {...{ descColor, ...mediaCard }} />
          ) : (
            // eslint-disable-next-line react/no-array-index-key
            <MediaFlipCard key={index} {...{ descColor, ...mediaCard }} />
          );
        })}
      </div>
    </SectionWrapper>
  );
};

export default MultiColumnComponent;
