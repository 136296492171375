/* eslint-disable import/no-cycle */
import featuredContentBlade from "./featuredContentBlade/featuredContentBlade";
import featureGridBlade from "./featureGridBlade/featureGridBlade";
import featureText from "./featureText/featureText";
import heroBlade from "./heroBlade/heroBlade";
import carouselBlade from "./carouselBlade/carouselBlade";
import multiColumnBlade from "./multiColumnBlade/multiColumnBlade";
import mediaCards from "./mediaCards/mediaCards";
import tabs from "./tabs/tabs";
import tabContents from "./tabContents/tabContents";
import gridBlade from "./gridBlade/gridBlade";
import resources from "./resources/resources";
import informativeModal from "./informativeModal/informativeModal";
import mainBladeDetails from "./mainBladeDetails/mainBladeDetails";
import skillbuilderHeroBlade from "./skillbuilderHeroBlade/skillbuilderHeroBlade";
import tabsBlade from "./tabsBlade/tabsBlade";
import contributeBlade from "./contributeBlade/contributeBlade";
import accordion from "./Accordion";
import header from "./header";
import singleRowContent from "./singleRowContent/singleRowContent";
import sideBySideContent from "./sideBySideContent/sideBySideContent";
import richTextComponent from "./RichText";
import tabbedContent from "./tabbedContent/tabbedContent";
import contactUsBlade from "./contactUsBlade";
import sortableBlade from "./sortableBlade/sortableBlade";
import partnersComponent from "./partnersComponent/partnersComponent";
import embedIFrame from "./embedIFrame/embedIFrame";
import RichTextComponent from "./RichTextComponent";
import HeroBladeGraphQL from "./HeroBladeGraphQL/heroBladeGraphQL";
import challengeList from "./challengeListBlade/index";

const WidgetMapping: Record<string, any> = {
  heroBlade,
  featuredContentBlade,
  featureGridBlade,
  featureText,
  carouselBlade,
  multiColumnBlade,
  mediaCards,
  tabs,
  tabContents,
  gridBlade,
  resources,
  informativeModal,
  mainBladeDetails,
  skillbuilderHeroBlade,
  tabsBlade,
  contributeBlade,
  accordion,
  header,
  singleRowContent,
  sideBySideContent,
  richTextComponent,
  tabbedContent,
  contactUsBlade,
  sortableBlade,
  partnersComponent,
  embedIFrame,
  RichTextComponent,
  HeroBladeGraphQL,
  challengeList,
};

export default WidgetMapping;
