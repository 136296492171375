/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from "react";
import ImageComponent from "../Image";

import styles from "./videoPlayer.module.sass";

export interface IVideoPlayerProps {
  classes?: string;
  videoUrl: string;
  aspectRatio?: string | number;
  autoplay?: boolean;
  isActive?: boolean;
  videoHeight?: string;
  videoWidth?: string;
}

const vimeoRegExp = /vimeo.*\/(\d+)/i;
const youtubeRegExp =
  /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;

const generateYouTubeUrl = (videoId: string): string => {
  return `https://www.youtube.com/embed/${videoId}?rel=0&autoplay=1&enablejsapi=1&modestbranding=1`;
};

const generateVimeoUrl = (videoId: string): string => {
  return `https://player.vimeo.com/video/${videoId}`;
};

const PlayButton = (): any => {
  return (
    <span className={styles.playbuttonContainer}>
      <span className={styles.playbuttonCircle}>
        <span className={styles.playbutton}>
          <span className={styles.playshadow}>&#9658;</span>
        </span>
      </span>
    </span>
  );
};

const VideoPlayer: FC<IVideoPlayerProps> = (props) => {
  const {
    classes = "",
    videoUrl,
    aspectRatio = "16/9",
    autoplay = false,
    isActive = true,
    videoHeight,
    videoWidth,
  } = props;
  // const [host, setHost] = useState<string>("");
  const [videoId, setVideoID] = useState<string>("");
  const [videoType, setVideoType] = useState<number>(0); // videoType: 1 = YouTube, 2 = Vimeo
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const clickPlaceholder = (): any => {
    return setIsClicked(true);
  };

  const generateYouTubePlaceholder = (videoId: string): JSX.Element => {
    /*
      gets the video id we mentioned in the data-embed attribute
      to generate image thumbnail urls, youtube has several
      resolutions.
      - mqdefault 320 x 180 <<-- This is the only one that doesn't return 404s
      - hqdefault 480 x 360
      - sddefault - 640 x 480
      - maxresdefault - 1920 x 1080
    */

    const aspectRatioNum =
      typeof aspectRatio === "string"
        ? parseInt(aspectRatio.split("/")[0], 10) /
          parseInt(aspectRatio.split("/")[1], 10)
        : aspectRatio;

    const src = `https://i3.ytimg.com/vi/${videoId}/mqdefault.jpg`;
    const aspectRatioData =
      !videoHeight && !videoWidth ? aspectRatioNum.toString() : "";
    return (
      <div
        onClick={clickPlaceholder}
        style={{ cursor: "pointer" }}
        className={`${styles.video_placeholder}`}
      >
        <ImageComponent
          image={src}
          aspectRatio={aspectRatioData} // Set the same aspect ratio as the iframe
          width={videoWidth}
          height={videoHeight}
        >
          <PlayButton />
        </ImageComponent>
      </div>
    );
  };

  const generateIFrame = (parsedVideoUrl: string) => {
    const aspectRatioNum =
      typeof aspectRatio === "string"
        ? parseInt(aspectRatio.split("/")[0], 10) /
          parseInt(aspectRatio.split("/")[1], 10)
        : aspectRatio;
    return (
      <iframe
        // loading="lazy"
        ref={(node) => {
          if (node && videoWidth) {
            node.style.setProperty("max-width", videoWidth, "important");
          }
        }}
        src={
          isActive ? `${parsedVideoUrl}${autoplay ? "?autoplay=1" : ""}` : ""
        }
        frameBorder="0"
        allow="autoplay; fullscreen; encrypted-media"
        style={{
          minHeight: videoHeight && videoHeight,
          aspectRatio:
            !videoHeight && !videoWidth ? aspectRatioNum.toString() : "",
        }}
        title="video"
        className={`${styles.videoPlayer} container is-flex`}
      />
    );
  };
  // const parsedVideoUrl = getParserVideoUrls(videoUrl, host);
  useEffect(() => {
    // setHost(window.location.hostname || "localhost");
    const vimeoMatch = vimeoRegExp.exec(videoUrl);
    const youtubeMatch = videoUrl.match(youtubeRegExp);

    if (vimeoMatch) {
      setVideoType(2);
      setVideoID(vimeoMatch[1]);
    }
    if (youtubeMatch) {
      setVideoType(1);
      setVideoID(youtubeMatch[2]);
    }
  }, [isClicked, videoUrl]);

  let videoContent;
  if (videoType === 1) {
    if (isClicked) {
      videoContent = generateIFrame(generateYouTubeUrl(videoId));
    } else {
      videoContent = generateYouTubePlaceholder(videoId);
    }
  }
  if (videoType === 2) {
    videoContent = generateIFrame(generateVimeoUrl(videoId));
  }
  if (videoContent)
    return (
      <div
        className={`${classes} videoPlayer ${styles.videoPlayer} is-flex is-align-items-center`}
      >
        {videoContent}
      </div>
    );
  return null;
};

export default VideoPlayer;
