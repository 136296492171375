import React, { FC, useEffect, useState } from "react";

import { CType } from "../../models/Contentful/types";
import ContentList from "../ContentList";
import EventCard from "../EventCard";
import FilterBar from "../ContentList/FilterBar";
import Header from "../ContentList/header";
import { IContentListProps } from "../ContentList/@types";
import { IEventCardProps } from "../EventCard/@types";
import LoadMoreButton from "../ContentList/LoadMoreButton";
import SectionWrapper from "../SectionWrapper";
import { getTagList } from "../../models/Contentful";
import styles from "./eventlist.module.sass";
import useContentList from "../ContentList/useContentList";

type TEventEntry = CType<IEventCardProps>;
const PAGE_SIZE = 6;
const EventList: FC<IContentListProps> = (props) => {
  const { title, description } = props;
  const [categoryList, setCategoryList] = useState<string[]>([]);
  const {
    hasMore,
    loadMore,
    list: eventsList,
    filter,
    applyFilter,
    total,
    currentPage,
  } = useContentList<IEventCardProps>({
    contentType: "event",
    itemsPerPage: PAGE_SIZE,
    filter: {
      order: "sys.updatedAt",
    },
  });
  useEffect(() => {
    getTagList("event", "category").then(setCategoryList);
  }, []);

  return (
    <SectionWrapper hasBodyMargin={false}>
      <Header title={title} description={description} />
      <FilterBar
        containerClass={styles.filterContainer}
        primary={[
          {
            key: "category",
            label: "Show All",
            list: categoryList,
          },
        ]}
        onChange={(key: string, value?: string) => {
          applyFilter({ ...filter, [`fields.${key}`]: value ?? "" });
        }}
      />
      <SectionWrapper hasBodyMargin={false}>
        <ContentList<TEventEntry>
          items={eventsList}
          keyExtractor={(challenge: TEventEntry) =>
            (challenge.sys?.id ?? "") as string
          }
          renderItem={(item: TEventEntry) => <EventCard {...item} />}
          layout="list"
        />
      </SectionWrapper>
      <SectionWrapper>
        <LoadMoreButton
          hasMore={!!eventsList?.length && hasMore}
          loadMore={loadMore}
          meta={{
            total,
            fetchedCount: eventsList.length,
            pageSize: PAGE_SIZE,
            currentPage,
          }}
        />
      </SectionWrapper>
    </SectionWrapper>
  );
};

export default EventList;
