/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
import { FC } from "react";
import { Document } from "@contentful/rich-text-types";
import Button, { ThemeType } from "../Button";
import { CLinkProps, CMedia } from "../../models/Contentful/types";

import { IHeroBladeProps } from "../../blades/heroBlade/@types";
import ImageComponent from "../Image";
import RichText from "../RichText";
import SectionWrapper from "../SectionWrapper";
import Typo from "../Typo";
import VideoPlayer from "../VideoPlayer";
import styles from "./heroContent.module.sass";
import { useIsTabletViewport } from "../../hooks/useIsMobileViewport";

export interface IHeroHeadingProps {
  title: string;
}

export interface IHeroDescription {
  description: Document;
}
export interface IFeaturedContentProps {
  isTablet: boolean;
  description: Document;
  links?: CLinkProps[];
}

export interface IHeroMedia {
  isTablet: boolean;
  image?: CMedia;
  videoUrl?: string;
}

const HeroHeading: FC<IHeroHeadingProps> = (props) => {
  const { title } = props;
  return (
    <Typo h1 classes="is-color-HighLightLime">
      {title}
    </Typo>
  );
};

const HeroDescription: FC<IHeroDescription> = (props) => {
  const { description } = props;
  return (
    <div className={styles.featuredContainer}>
      <Typo big>
        <RichText document={description} />
      </Typo>
    </div>
  );
};

const HeroMedia: FC<IHeroMedia> = (props) => {
  const { isTablet, image, videoUrl } = props;
  return (
    <div className={styles.artistContainer}>
      <div>
        <ImageComponent
          image="/static/images/homePage/heroBlade/circlePoints.svg"
          height={isTablet ? "59px" : "118px"}
          width={isTablet ? "59px" : "118px"}
          classes={styles.pointedCircle}
        />
        <ImageComponent
          image="/static/images/homePage/heroBlade/borderCircle.svg"
          height={isTablet ? "129px" : "179px"}
          width={isTablet ? "129px" : "179px"}
          classes={styles.borderCircle}
        />
        <div>
          {!videoUrl ? (
            <div className={styles.imageComponent}>
              <ImageComponent image={image} width={500} height={400} />
            </div>
          ) : (
            <div className={styles.videoPlayer}>
              <VideoPlayer videoUrl={videoUrl} />
            </div>
          )}
        </div>
        <ImageComponent
          image="/static/images/homePage/heroBlade/greenLines.svg"
          height={isTablet ? "100px" : "118px"}
          width={isTablet ? "95px" : "118px"}
          classes={styles.greenLines}
        />
      </div>
    </div>
  );
};

const FeaturedContent: FC<IFeaturedContentProps> = (props) => {
  const { isTablet, description, links = [] } = props;
  return (
    <div
      // eslint-disable-next-line prettier/prettier
      className={`${isTablet ? "has-text-centered" : ""} ${
        styles.featuredContent
        // eslint-disable-next-line prettier/prettier
      }`}
    >
      <HeroDescription description={description} />
      <div className={`${styles.featuredContainer}`}>
        {links && links.length > 0 && (
          <div className={`is-flex ${styles.featuredButtons}`}>
            {links.map((link, index) => {
              const isBtn = !!link.fields.layout;
              return (
                <Button
                  isBtn={isBtn}
                  theme={link.fields.layout as ThemeType}
                  link={link}
                  key={index}
                  classes="is-color-DarkBlue"
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const Hero: FC<IHeroBladeProps> = (props) => {
  const { title, description, links, image, videoUrl } = props;
  const { isTablet } = useIsTabletViewport();
  return (
    <SectionWrapper>
      <div className={`${styles.heroContainer}`}>
        <div className={isTablet ? "has-text-centered	" : ""}>
          <HeroHeading title={title} />
          <HeroMedia {...{ isTablet, image, videoUrl }} />
          <FeaturedContent {...{ isTablet, description, links }} />
        </div>
      </div>
    </SectionWrapper>
  );
};

export default Hero;
