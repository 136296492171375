/* eslint-disable import/no-cycle */
import SectionWrapper from "../../components/SectionWrapper";
import { CType } from "../../models/Contentful/types";
import { IAccordionProps } from "../../components/Accordion/@types";
import { logger } from "../../utils";
import Accordion from "../../components/Accordion";

const AccordionWrapper: React.FC<CType<IAccordionProps>> = (props) => {
  const { fields } = props;
  try {
    return (
      <SectionWrapper>
        <Accordion heading={fields.heading} items={fields.items} />
      </SectionWrapper>
    );
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export default AccordionWrapper;
