import { logger } from "../../utils";
import FeatureGrid from "../../components/FeatureGrid";
import { CType } from "../../models/Contentful/types";
import { IFeatureGridBladeProps } from "./@types";

const FeatureGridBlade: React.FC<CType<IFeatureGridBladeProps>> = (props) => {
  try {
    const {
      fields: { ...data },
    } = props;

    return <FeatureGrid {...data} />;
  } catch (error) {
    logger.error(error);
    return null;
  }
};
export default FeatureGridBlade;
