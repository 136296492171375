/* eslint-disable import/order */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-cycle */
import { FC } from "react";
import { Document } from "@contentful/rich-text-types";
import clsx from "clsx";

import Button, { ThemeType } from "../Button";
import { CLinkProps, CMedia } from "../../models/Contentful/types";
import ImageComponent from "../Image";
import RichText from "../RichText";
import SectionWrapper from "../SectionWrapper";
import { SideBySideContentProps } from "../../blades/sideBySideContent/@types";
import Typo from "../Typo";
import VideoPlayer from "../VideoPlayer";
import styles from "./sideBySide.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";
import Icons from "../SvgLib/icons";

export interface SideBySideProps extends SideBySideContentProps {
  backgroundColor: string;
  titleColor: string;
  descColor: string;
}
export interface TextComponentProps {
  title: string;
  description: Document;
  titleColor: string;
  descColor: string;
  links?: CLinkProps[];
}

export interface MediaSBSComponentProps {
  isMobile: boolean;
  titleColor: string;
  videoUrl?: string;
  image?: CMedia;
  caption?: string;
}

const TextComponent: FC<TextComponentProps> = (props) => {
  const { title, description, titleColor, descColor, links } = props;
  return (
    <div className="is-flex is-flex-direction-column">
      <Typo h2 classes={`is-color-${titleColor} mb-2`}>
        {title}
      </Typo>
      <Typo normal classes={`is-color-${descColor}`}>
        <RichText
          document={description}
          classes={`rich-text-container ${styles.wordBreak}`}
        />
      </Typo>
      {links && links.length > 0 && (
        <div className={styles.button}>
          {links.map((link, index) => {
            const isBtn = !!link.fields.layout;
            return (
              <Button
                isBtn={isBtn}
                theme={link.fields.layout as ThemeType}
                link={link}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const MediaSBSComponent: FC<MediaSBSComponentProps> = (props) => {
  const { titleColor, videoUrl, image, caption } = props;
  return (
    <div className={clsx(styles.mediaContainer, "media-container")}>
      {videoUrl ? (
        <VideoPlayer videoUrl={videoUrl} />
      ) : (
        <ImageComponent
          image={image}
          classes={styles.imageBorder}
          // aspectRatio="16/9"
        />
      )}
      {videoUrl && caption && (
        <Button
          isExternalLink
          isBtn={false}
          openInSameTab={false}
          linkToUrl={videoUrl.replace("/embed/", "/watch/")}
        >
          <Typo classes={`normal is-color-${titleColor} mt-2`}>
            {caption} {Icons({})["external-link"]}
          </Typo>
        </Button>
      )}
      {!videoUrl && caption && (
        <Typo classes={`normal is-color-${titleColor} mt-2`}>{caption}</Typo>
      )}
    </div>
  );
};

const SideBySide: FC<SideBySideProps> = (props) => {
  const {
    title,
    description,
    image,
    imagePosition,
    caption,
    backgroundColor,
    titleColor,
    descColor,
    links,
    videoUrl,
  } = props;
  const { isMobile } = useIsMobileViewport();
  return (
    <SectionWrapper classes={`${styles.root} `} childClasses="parent-container">
      <div className={`is-bgcolor-${backgroundColor} ${styles.body} `}>
        <SectionWrapper>
          <div
            className={clsx(
              styles.bodyContent,
              imagePosition === "Left" ? styles.mediaLeft : styles.mediaRight,
            )}
          >
            {imagePosition === "Left" && (
              <>
                <MediaSBSComponent
                  {...{ videoUrl, image, caption, titleColor, isMobile }}
                />
                <TextComponent
                  {...{ title, titleColor, descColor, description, links }}
                />
              </>
            )}
            {imagePosition === "Right" && (
              <>
                <TextComponent
                  {...{ title, titleColor, descColor, description, links }}
                />
                <MediaSBSComponent
                  {...{ videoUrl, image, caption, titleColor, isMobile }}
                />
              </>
            )}
          </div>
        </SectionWrapper>
      </div>
    </SectionWrapper>
  );
};

export default SideBySide;
