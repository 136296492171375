/* eslint-disable import/no-cycle */
import { logger } from "../../utils";
import MediaCardBlade from "../../components/MediaCards/MediaCardBlade";
import { CType } from "../../models/Contentful/types";
import { IMediaCardsProps } from "../multiColumnBlade/@types";

const MultiColumn: React.FC<CType<IMediaCardsProps>> = (props) => {
  try {
    return (
      <div className="py-4">
        <MediaCardBlade {...props} />
      </div>
    );
  } catch (error) {
    logger.error(error);
    return null;
  }
};
export default MultiColumn;
