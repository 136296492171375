/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-cycle */
import { FC } from "react";
import { Document } from "@contentful/rich-text-types";
import Link from "next/link";
import clsx from "clsx";
import router from "next/router";

import { CMedia, CType, Media } from "../../models/Contentful/types";
import { IWinnerCardProps, WinnerCategory } from "./@types";
import Button from "../Button";
import { IPartnerCardProps } from "../../models/Partner/@types";
import ImageComponent from "../Image";
import { ROUTES } from "../../Constants/variables";
import Typo from "../Typo";
import VideoPlayer from "../VideoPlayer";
import { getChallengeUrl } from "../../models/Challenge";
import { getStrippedString } from "../../helpers";
import helpers from "../../utils/helpers";
import styles from "./winnerCard.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";
import DateTimeRenderer from "../DateTime/dateTime";

interface IFirstComponetProps {
  dueDate: string;
  industry: string;
  partners: CType<IPartnerCardProps>[];
  image: CMedia;
  isMobile: boolean;
  category: WinnerCategory;
  media?: string;
  videoUrl?: string;
}

interface ISecondComponent {
  teamName: string;
  title: string;
  description: string | Document;
  duration: string;
  skillNames: string[];
  earnPoints: number;
  isMobile: boolean;
  charactersAllowed: number;
  defaultUrl: string;
  winnerType: string;
  rankIcon?: CType<Media>;
}

interface IThirdComponent {
  isMobile: boolean;
  winnerType: string;
  submissionUrl?: string;
  buttonText?: string;
  buttonUrl?: string;
  award?: string;
}

const FirstComponent: FC<IFirstComponetProps> = (props) => {
  const { dueDate, industry, partners, image, isMobile, media, videoUrl } =
    props;
  return (
    <div className="is-flex is-flex-direction-column ">
      {/* BASIC DETAILS */}
      <div className="is-flex">
        {dueDate && (
          <DateTimeRenderer
            date={dueDate}
            textBefore="Due: "
            typoProps={{ small: true }}
            typoClasses={clsx("is-color-BlackHalf", styles.dateContainer)}
          />
        )}

        <Typo small classes="is-color-BlackHalf">
          {industry}
        </Typo>
      </div>
      {/* IMAGE AND PARTNERS WITH BORDER */}
      <div className={styles.imageContainer}>
        {media === "Video" && videoUrl ? (
          <VideoPlayer
            videoUrl={videoUrl}
            classes={styles.videoPlayer}
            aspectRatio={isMobile ? "16/9" : "19/16"}
          />
        ) : (
          <ImageComponent
            image={image}
            width="100%"
            height={isMobile ? "146px" : "195px"}
            aspectRatio="9/16"
            crop
          />
        )}
        <div>
          {partners.map((partner, index) => {
            return (
              index < 3 && (
                <div key={index} className={styles.sponsorImage}>
                  <ImageComponent
                    image={partner?.fields?.image}
                    height={isMobile ? "26px" : "35px"}
                  />
                </div>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
};

const SecondComponent: FC<ISecondComponent> = (props) => {
  const {
    teamName,
    title,
    description,
    skillNames,
    earnPoints,
    duration,
    isMobile,
    defaultUrl,
    winnerType,
    rankIcon,
    // charactersAllowed,
  } = props;

  const updatedDescription = !isMobile
    ? getStrippedString(description, 92)
    : getStrippedString(description);
  return (
    <div
      className={`${styles.winnerMain} ${
        isMobile && "is-flex is-align-items-center	is-flex-direction-column"
      }`}
    >
      <div className="is-flex is-align-items-left">
        {/* <div className={styles.icons}> */}
        {/* <SvgLib icon="circleFrame" />
          <SvgLib icon="firstPlace" /> */}
        <ImageComponent image={rankIcon} height={46} width={46} />
        {/* </div> */}
        <Typo h4 classes={styles.teamWidth}>
          {teamName}
        </Typo>
      </div>
      <div className={`${isMobile && "has-text-centered"}`}>
        <Link href={defaultUrl}>
          <Typo h3 classes="is-color-Blue">
            {title}
          </Typo>
        </Link>
      </div>
      {/* {!isMobile && ( */}
      <div className={`${isMobile && "has-text-centered"}`}>
        <Typo normal classes="is-color-Black">
          {updatedDescription.string}
        </Typo>
        {updatedDescription.charLeftCount &&
        updatedDescription.charLeftCount > 0 ? (
          <Link href={defaultUrl}>
            <Typo normal classes="is-color-Blue">
              ...Learn more
            </Typo>
          </Link>
        ) : (
          <div />
        )}
      </div>
      {/* )} */}
      {/* {!isMobile && ( */}
      <div className="is-flex">
        {winnerType === "challenge" ? (
          <Typo small>{`${duration} Challenge`}</Typo>
        ) : (
          <Typo small>{`Effort: ${duration}`}</Typo>
        )}
        <Typo small>{`Earns: +${earnPoints}pts`}</Typo>
      </div>
      {/* )} */}
      <div className="is-flex is-justify-content-space-between">
        {skillNames.map((skill, index) => {
          return (
            index < 3 && (
              <Typo small classes="is-color-BlackHalf mr-1" key={index}>
                {skill}
              </Typo>
            )
          );
        })}
      </div>
    </div>
  );
};

const ThirdComponent: FC<IThirdComponent> = (props) => {
  const { award, isMobile, submissionUrl, buttonText, buttonUrl, winnerType } =
    props;

  return (
    <div className="is-flex is-justify-content-space-between is-flex-direction-column">
      {award ? (
        <div className="has-text-centered">
          <Typo normal>Awarded:</Typo>
          <br />
          <Typo normal>{award}</Typo>
        </div>
      ) : (
        <div />
      )}
      <div
        className={`has-text-centered is-flex  ${
          isMobile
            ? "is-flex-direction-row is-justify-content-center is-align-items-baseline mb-4"
            : "is-flex-direction-column"
        }`}
        style={{ gap: "10px" }}
      >
        <Button
          theme="Filled - Lime"
          onClick={() => buttonUrl && router.push(buttonUrl)}
          disabled={buttonText === "Completed"}
        >
          {buttonText}
        </Button>
        {submissionUrl && winnerType === "challenge" && (
          <Button heightLarge onClick={() => router.push(submissionUrl)}>
            View Winning Submission
          </Button>
        )}
      </div>
    </div>
  );
};

const WinnerCard: FC<IWinnerCardProps> = (props) => {
  const { isMobile } = useIsMobileViewport();

  const { teamName, winnerOf, category, submissionUrl, rankIcon, award } =
    props;
  const { fields } = winnerOf;
  const {
    title,
    description,
    image,
    industryList,
    skills,
    earnPoints,
    // award,
    dueDate,
    duration,
    partners,
    charactersAllowed,
    status,
    project_id,
    slug,
    videoUrl,
    media,
  } = fields;

  let buttonUrl = "";
  let buttonText = "Do It";
  let defaultUrl = "";
  const winnerType = winnerOf.sys?.contentType?.sys?.id;

  if (winnerType === "challenge") {
    defaultUrl = getChallengeUrl(fields);
  } else if (winnerType === "skillbuilder") {
    const linkToUrl = `/skillbuilders/${slug}`;
    defaultUrl = linkToUrl;
  }

  switch (status) {
    case "active": {
      const linkToUrl = helpers.generatePath(ROUTES.PROJECT, {
        projectId: project_id as string,
      });
      buttonUrl = linkToUrl;
      buttonText = "View Challenge";
      break;
    }
    case "completed":
      buttonText = "Completed";
      break;

    default:
      buttonUrl = defaultUrl;
      break;
  }

  const industry = industryList?.fields?.title?.join(", ");
  const skillNames = skills?.fields?.title ?? [];
  return (
    <div className={`${styles.winnerCard} container`}>
      <FirstComponent
        {...{
          dueDate,
          industry,
          image,
          partners,
          category,
          isMobile,
          media,
          videoUrl,
        }}
      />
      <SecondComponent
        {...{
          title,
          description,
          skillNames,
          earnPoints,
          duration,
          teamName,
          isMobile,
          defaultUrl,
          charactersAllowed,
          winnerType,
          rankIcon,
        }}
      />
      <ThirdComponent
        {...{
          award,
          isMobile,
          winnerType,
          submissionUrl,
          buttonText,
          buttonUrl,
        }}
      />
    </div>
  );
};

export default WinnerCard;
