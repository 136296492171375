/* eslint-disable import/no-cycle */
import { logger } from "../../utils";
import FeaturedContent from "../../components/FeaturedContent";
import { CType } from "../../models/Contentful/types";
import { IFeaturedContentBladeProps } from "./@types";

const FeaturedContentBlade: React.FC<CType<IFeaturedContentBladeProps>> = (
  props,
) => {
  try {
    const {
      fields: { ...data },
    } = props;
    return <FeaturedContent {...data} />;
  } catch (error) {
    logger.error(error);
    return null;
  }
};
export default FeaturedContentBlade;
