/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
import React, { useState } from "react";
import clsx from "clsx";

import Button from "../Button";
import SectionWrapper from "../SectionWrapper";
import { TabsContentComponentProps } from "../../blades/tabbedContent/@types";
import Typo from "../Typo";
import Widgets from "../../blades/Widgets";
import styles from "./tabsContentComponent.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";

const TabsContentComponent = (props: TabsContentComponentProps) => {
  const { heading, description, tabItems } = props;
  const [toggleState, setToggleState] = useState(0);

  const { isMobile } = useIsMobileViewport();

  const toggleTab = (index: number) => {
    setToggleState(index);
  };

  return (
    <div className={styles.root}>
      <SectionWrapper>
        <Typo h2 classes="mb-3 has-text-centered">
          {heading}
        </Typo>
        <div className={`${styles.description} has-text-centered`}>
          <Typo medium={!isMobile} big={isMobile}>
            {description}
          </Typo>
        </div>
      </SectionWrapper>
      <div className={`${styles.tabRoot} mt-4`}>
        {tabItems && (
          <div className={`${styles.tabTitle}`}>
            {tabItems.map((tabItem, index) => {
              return (
                <Button
                  key={index}
                  onlyText
                  onClick={() => toggleTab(index)}
                  classes={clsx(
                    {
                      selected: toggleState === index,
                    },
                    toggleState === index && styles.activeTabs,
                    styles.tabs,
                  )}
                >
                  <Typo h4={!isMobile} h3={isMobile}>
                    {tabItem.fields.title}
                  </Typo>
                </Button>
              );
            })}
          </div>
        )}
        {tabItems && (
          <div
            className={`${styles.tabContainer} is-flex is-flex-direction-column`}
          >
            {tabItems.map((tabItem, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    toggleState === index && styles.activeContent
                  } ${styles.content}`}
                >
                  <Widgets widget={tabItem.fields.widget} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default TabsContentComponent;
