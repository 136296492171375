import React, { FC } from "react";

import { CType } from "../../models/Contentful/types";
import { IContactUsBlade } from "../../blades/contactUsBlade/@types";
import { IRichTextBladeProps } from "../../blades/RichText";
import RichText from "../RichText";
import SectionWrapper from "../SectionWrapper";
import styles from "./contactUs.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";

const FormColumn: FC<CType<IRichTextBladeProps>> = (props) => {
  const { fields } = props;
  const { text } = fields;
  return (
    <div>
      <RichText document={text} />
    </div>
  );
};

const FAQList: FC<CType<IRichTextBladeProps>> = (props) => {
  const { fields } = props;
  const { text } = fields;
  return (
    <div>
      <RichText document={text} classes={styles.faqRichText} />
    </div>
  );
};

const Relations: FC<CType<IRichTextBladeProps>> = (props) => {
  const { fields } = props;
  const { text } = fields;
  return (
    <div className={styles.relations}>
      <RichText document={text} />
    </div>
  );
};

const ContactUs: FC<IContactUsBlade> = (props) => {
  const { isMobile } = useIsMobileViewport();
  const { formField, faqQuestions, relation } = props;

  return (
    <SectionWrapper hasBodyMargin={!isMobile}>
      <div className={`${styles.root}`}>
        <FormColumn {...formField} />
        <div>
          <FAQList {...faqQuestions} />
          <Relations {...relation} />
        </div>
      </div>
    </SectionWrapper>
  );
};

export default ContactUs;
