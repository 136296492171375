import React, { FC } from "react";

import Button from "../Button";
import { IAccordionItemProps } from "./@types";
import ImageComponent from "../Image";
import RichText from "../RichText";
import SvgLib from "../SvgLib";
import Typo from "../Typo";
import styles from "./accordion.module.sass";

const AccordionItem: FC<IAccordionItemProps> = (props) => {
  const { label, description, isOpen = false, onClick, image } = props;

  return (
    <div className={styles.accordionItemContainer}>
      <Button
        classes={styles.accordionItemButtonContainer}
        heightLarge
        onClick={onClick}
      >
        <div className={styles.accordionItemIconContainer}>
          <SvgLib
            icon={isOpen ? "accordion-up" : "accordion-down"}
            iconPos="left"
            iconSize="Medium"
            classes={isOpen ? styles.minus : styles.plus}
          />
          <Typo medium>{label}</Typo>
        </div>
      </Button>
      {isOpen && (
        <div className={styles.accordionItemDescription}>
          {image && <ImageComponent image={image} aspectRatio={16 / 9} />}
          <RichText document={description} classes="rich-text-container" />
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
