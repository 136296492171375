import { FC } from "react";
import SideBySide from "../../components/SideBySide";
import { logger } from "../../utils";
import ThemeDefinition from "../../utils/Theme";
import { CType } from "../../models/Contentful/types";
import { SideBySideContentProps } from "./@types";

const SideBySideContent: FC<CType<SideBySideContentProps>> = (props) => {
  try {
    const {
      fields: { ...data },
    } = props;

    const { backgroundColor, titleColor, descColor } = ThemeDefinition(
      data.theme,
    );
    return (
      <SideBySide {...{ titleColor, descColor, backgroundColor, ...data }} />
    );
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export default SideBySideContent;
