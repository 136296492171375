/* eslint-disable import/no-cycle */
import { logger } from "../../utils";
import Hero from "../../components/Hero";
import { IHeroBladeProps } from "../heroBlade/@types";

const HeroBladeGraphQL: React.FC<IHeroBladeProps> = (props) => {
  try {
    return <Hero {...props} />;
  } catch (error) {
    logger.error(error);
    return null;
  }
};
export default HeroBladeGraphQL;
