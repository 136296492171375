import React, { FC, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { orderBy } from "lodash";
import { useRouter } from "next/router";

import {
  CHALLENGE_WINNER_SLUG,
  IWinnerCardProps,
  SKILLBUILDER_WINNER_SLUG,
  WINNERS_MAPPING,
} from "../WinnerCard/@types";
import AuthContext from "../../AppContext/AuthProvider";
import Button from "../Button";
import { CType } from "../../models/Contentful/types";
import ContentList from "../ContentList";
import Header from "../ContentList/header";
import { IContentListProps } from "../ContentList/@types";
import LoadMoreButton from "../ContentList/LoadMoreButton";
import SectionWrapper from "../SectionWrapper";
import WinnerCard from "../WinnerCard";
import { WinnersTabs } from "../../models/Winners";
import { getChallengeDetailsForUser } from "../../models/Challenge";
import { getSkillbuilderDetailsForUser } from "../../models/SkillBuilder/api";
import { getTabLink } from "../../utils/helpers";
import styles from "./winnerList.module.sass";
import useContentList from "../ContentList/useContentList";

type TWinnerEntry = CType<IWinnerCardProps>;
const PAGE_SIZE = 10;
const WinnerList: FC<IContentListProps> = (props) => {
  const { title, description } = props;

  const router = useRouter();
  const routerAsPath = router.query?.slug || [];
  let currentTab = "";
  let categoryType = "";
  if (routerAsPath.includes("skillbuilders")) {
    currentTab = SKILLBUILDER_WINNER_SLUG;
    categoryType = WINNERS_MAPPING.SKILLBUILDER_WINNERS;
  } else if (routerAsPath.includes("challenges")) {
    currentTab = CHALLENGE_WINNER_SLUG;
    categoryType = WINNERS_MAPPING.CHALLENGE_WINNERS;
  }

  const {
    hasMore,
    loadMore,
    list: winnerList,
    total,
    currentPage,
  } = useContentList<IWinnerCardProps>({
    contentType: "winners",
    itemsPerPage: PAGE_SIZE,
    filter: {
      order: "-sys.createdAt",
      "fields.category": categoryType,
    },
  });
  const [mutatedWinners, setMutatedWinners] = useState<TWinnerEntry[]>([]);
  const { appUser } = useContext(AuthContext);

  const winnersTabsObj = [{ name: "All Winners", slug: "" }, ...WinnersTabs];

  useEffect(() => {
    const mutateWinnerData = async () => {
      const challengesWinner = winnerList.filter(
        (list) =>
          list?.fields?.winnerOf?.sys?.contentType?.sys?.id === "challenge",
      );

      const challengesIdList = challengesWinner.map((item) => {
        return (
          item?.fields?.winnerOf?.sys?.id ??
          item?.fields?.winnerOf?.fields?.slug
        );
      });

      const challenges = challengesWinner.map(
        (winner) => winner.fields.winnerOf,
      );
      const newChallenge =
        (challengesIdList.length &&
          (await getChallengeDetailsForUser(challengesIdList, challenges))) ||
        [];
      const newChallengeWinners = challengesWinner.map((cData) => {
        const newData = {
          ...cData,
          fields: {
            ...cData.fields,
            winnerOf: {
              ...newChallenge.find(
                (data) =>
                  data?.sys?.id === cData?.fields?.winnerOf?.fields?.entryId ||
                  data?.fields?.slug === cData?.fields?.winnerOf?.fields?.slug,
              ),
            },
          },
        };
        return { ...newData };
      });
      const skillbuilderWinner = winnerList.filter(
        (list) =>
          list?.fields?.winnerOf?.sys?.contentType?.sys?.id === "skillbuilder",
      );

      const skillbuilderIdList = skillbuilderWinner.map((item) => {
        return (
          item?.fields?.winnerOf?.sys?.id ??
          item?.fields?.winnerOf?.fields?.slug
        );
      });

      const skillbuilders = skillbuilderWinner.map(
        (winner) => winner?.fields?.winnerOf,
      );

      const newSkillBuilder = await getSkillbuilderDetailsForUser(
        skillbuilderIdList || [],
        skillbuilders,
      );

      const newSkillbuilderWinners = skillbuilderWinner.map((sData) => {
        const newData = {
          ...sData,
          fields: {
            ...sData.fields,
            winnerOf: {
              ...newSkillBuilder.find(
                (data) =>
                  data.sys?.id === sData?.fields?.winnerOf?.fields?.entryId ||
                  data.fields?.slug === sData?.fields?.winnerOf?.fields?.slug,
              ),
            },
          },
        };
        return { ...newData };
      });

      let mutatedWinnersArray = [
        ...newChallengeWinners,
        ...newSkillbuilderWinners,
      ] as TWinnerEntry[];

      mutatedWinnersArray = orderBy(
        mutatedWinnersArray,
        "sys.createdAt",
        "desc",
      ) as TWinnerEntry[];

      setMutatedWinners(mutatedWinnersArray as TWinnerEntry[]);
    };
    appUser && mutateWinnerData();
  }, [appUser, winnerList]);
  return (
    <SectionWrapper hasBodyMargin={false}>
      <Header title={title} description={description} />
      <div className={`${styles.filterContainer} has-text-centered`}>
        {winnersTabsObj.map((tab) => (
          <Button
            ariaLabel={tab.slug}
            key={tab.slug}
            onlyText
            eventLink
            underline
            link={getTabLink(tab, "winners", "")}
            classes={clsx(styles.tabItem, {
              selected: tab.slug === currentTab,
            })}
          />
        ))}
      </div>

      <SectionWrapper hasBodyMargin={false}>
        <ContentList<TWinnerEntry>
          items={mutatedWinners?.length ? mutatedWinners : winnerList}
          keyExtractor={(challenge: TWinnerEntry) =>
            (challenge.sys?.id ?? "") as string
          }
          renderItem={(item: TWinnerEntry) => (
            <SectionWrapper
              hasBodyMargin={false}
              classes={clsx(
                styles.winnerItem,
                {
                  "is-bgcolor-Blue":
                    !item.fields.background ||
                    item.fields.background === "BackgroundBlue",
                },
                {
                  "is-bgcolor-Purple":
                    !item.fields.background ||
                    item.fields.background === "BackgroundPurple",
                },
                "py-5",
              )}
            >
              <WinnerCard {...item.fields} />
            </SectionWrapper>
          )}
          layout="list"
        />
      </SectionWrapper>
      <SectionWrapper>
        <LoadMoreButton
          hasMore={!!winnerList?.length && hasMore}
          loadMore={loadMore}
          meta={{
            total,
            fetchedCount: winnerList.length,
            pageSize: PAGE_SIZE,
            currentPage,
          }}
        />
      </SectionWrapper>
    </SectionWrapper>
  );
};

export default WinnerList;
