import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { getList } from "../../models/Contentful";
import { CType } from "../../models/Contentful/types";
import { TFilterQuery } from "../../models/Contentful/types/lists";
import { IContentListHook, IContentListHookProps } from "./@types";

export const useContentList = <TList>(
  config: IContentListHookProps,
): IContentListHook<CType<TList>> => {
  const { contentType, filter: defaultFilter, itemsPerPage = 4 } = config;
  const [list, setList] = useState<CType<TList>[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const { query } = useRouter();

  useEffect(() => {
    const page = parseFloat((query.page ?? 1) as string);
    setCurrentPage(page);
    const skipEntries = page < 2 ? 0 : (page - 1) * itemsPerPage;
    fetchList({}, skipEntries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setHasMore(total > currentPage * itemsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const fetchList = async (filter?: TFilterQuery, skipEntries = 0) => {
    const listResult = await getList<TList>(
      contentType,
      skipEntries,
      itemsPerPage,
      { ...defaultFilter, ...filter },
    );
    if (skipEntries === 0) setList(listResult.items);
    else setList([...list, ...listResult.items]);
    setTotal(listResult.total);
  };

  const loadMore = async () => {
    await fetchList(filter, currentPage * itemsPerPage);
    setCurrentPage(currentPage + 1);
  };

  const applyFilter = async (filter: TFilterQuery) => {
    setFilter(filter);
    setCurrentPage(1);
    fetchList(filter, 0);
  };

  return {
    total,
    list,
    loadMore,
    hasMore,
    applyFilter,
    filter,
    currentPage,
  };
};

export default useContentList;
