import React, { FC, useState } from "react";
import clsx from "clsx";
import { IFilterBarProps, IFilterItem } from "./@types";

import DropdownWrapper from "../DropdownWrapper";
import SectionWrapper from "../SectionWrapper";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";
import styles from "./list.module.sass";

const FilterDropdown: FC<{
  item: IFilterItem;
  onSelect: (key: string, value?: string) => void;
  selected?: string;
}> = ({ item, onSelect, selected }) => {
  return (
    <div className={clsx(styles.filterContainer, "filterItem")}>
      <DropdownWrapper
        selectedItem={selected}
        setSelectedItem={(value?: string) => onSelect(item.key, value)}
        title={item.label}
        items={item.list}
        isFullWidth
        showIcon={item.hasDropDownIcon}
      />
    </div>
  );
};

const FilterBar: FC<IFilterBarProps> = ({
  primary,
  secondary,
  onChange,
  containerClass,
}) => {
  const [selections, setSelections] = useState<
    Record<string, string | undefined>
  >({});
  const { isMobile } = useIsMobileViewport();

  const onSelection = (key: string, value?: string) => {
    setSelections({ ...selections, [key]: value });
    onChange(key, value);
  };
  return (
    <SectionWrapper
      childClasses={clsx({ "is-flex": !isMobile }, containerClass)}
      classes={clsx(styles.filterBarRoot, { "py-4": !isMobile })}
    >
      <div
        className={clsx(
          { "is-flex": !isMobile },
          "is-flex-grow-1",
          "is-align-self-flex-start",
          styles.primaryFilterContainer,
          {
            "is-justify-content-center": !secondary?.length,
          },
        )}
      >
        {primary.map((filterItem) => (
          <FilterDropdown
            key={filterItem.key}
            item={filterItem}
            selected={filterItem.selected ?? selections[filterItem.key]}
            onSelect={onSelection}
          />
        ))}
      </div>
      <div
        className={clsx(
          "is-align-self-flex-end",
          styles.secondaryFilterContainer,
        )}
      >
        {secondary?.map((filterItem) => (
          <FilterDropdown
            key={filterItem.key}
            item={filterItem}
            selected={filterItem.selected ?? selections[filterItem.key]}
            onSelect={onSelection}
          />
        ))}
      </div>
    </SectionWrapper>
  );
};

export default FilterBar;
