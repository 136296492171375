/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
import React, { FC, useState } from "react";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";

import { Document } from "@contentful/rich-text-types";
import RichText from "../RichText";
import SectionWrapper from "../SectionWrapper";
import { SortableBladeProps } from "../../blades/sortableBlade/@types";
import SvgLib from "../SvgLib";
import Typo from "../Typo";
import styles from "./sortableComponent.module.sass";

interface ISortItemProps {
  heading: string;
  description: Document;
}

const SortItem: FC<ISortItemProps> = ({ heading, description }) => (
  <div className={`${styles.sortBlock} is-flex`}>
    <div>
      <Typo h4 bold classes="mb-4">
        {heading}
      </Typo>
      <Typo normal>
        <RichText document={description} />
      </Typo>
    </div>
    <div className={`mt-5 ${styles.sortIcon}`}>
      <SvgLib icon="sort-icon" iconSize="Large" />
    </div>
  </div>
);

const SortableParent: FC<{ children: React.ReactNode }> = ({ children }) => (
  <div>{children}</div>
);

const SortableComp = SortableContainer<{ children: React.ReactNode }>(
  SortableParent,
);
const SortableItem = SortableElement<ISortItemProps>(SortItem);

const SubHeader: FC<SortableBladeProps> = (props) => {
  const { heading, sortComponent } = props;

  const sortData: { heading: string; description: Document }[] =
    sortComponent?.map((sort) => {
      return {
        heading: sort.fields.title,
        description: sort.fields.description,
      };
    });

  const [sortDataItems, setSortDataItems] =
    useState<ISortItemProps[]>(sortData);

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    setSortDataItems(arrayMove(sortDataItems, oldIndex, newIndex));
  };

  return (
    <SectionWrapper>
      {heading && (
        <div className="my-6 has-text-centered">
          <Typo h2 classes="mb-4">
            {heading.fields?.heading}
          </Typo>
          {heading.fields?.description && (
            <div className="mx-6">
              <Typo normal>
                <RichText document={heading.fields?.description} />
              </Typo>
            </div>
          )}
        </div>
      )}
      {sortDataItems && (
        <SortableComp onSortEnd={onSortEnd}>
          {sortDataItems.map((item, index) => (
            <SortableItem
              index={index}
              key={index}
              heading={item.heading}
              description={item.description}
            />
          ))}
        </SortableComp>
      )}
    </SectionWrapper>
  );
};

export default SubHeader;
