/* eslint-disable import/no-named-default */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-cycle */
import { FC, useState } from "react";
import shortid from "shortid";
import {
  convertRichTextToPlainText,
  getStrippedString,
} from "../../../helpers";

import Button from "../../Button";
import { CType } from "../../../models/Contentful/types";
import { IMediaCardsProps } from "../../../blades/multiColumnBlade/@types";
import ImageComponent from "../../Image";
import RichText from "../../RichText";
import Typo from "../../Typo";
import { default as styles } from "./mediaFlipCard.module.sass";

const MediaFlipCard: FC<CType<IMediaCardsProps>> = (props) => {
  const { fields } = props;
  const { title, description, media, flipCardLink } = fields;

  const FLIP_CARD_CHAR_COUNT = flipCardLink ? 287 : 310;

  let remainingCharacterCount = FLIP_CARD_CHAR_COUNT;

  const [isFlipped, setIsFlipped] = useState<boolean>(false);

  const lenFlipCardLink =
    flipCardLink && convertRichTextToPlainText(flipCardLink).length;

  if (lenFlipCardLink) {
    remainingCharacterCount = FLIP_CARD_CHAR_COUNT - lenFlipCardLink;
  }
  const updatedDescription =
    description && getStrippedString(description, remainingCharacterCount);
  return (
    <div className={`${styles.flipCard} has-text-centered `}>
      <div className={`${styles.flipCardInner} ${isFlipped && styles.isFlip}`}>
        <div
          className={`${styles.flipCardFront}`}
          onClick={() => setIsFlipped(true)}
        >
          {media && (
            <div className={`${styles.imageComponent}`}>
              <ImageComponent
                image={media}
                aspectRatio="16 / 9"
                width="100%"
                height="auto"
              />
            </div>
          )}
          {title && (
            <div className={`${styles.titleContainer} `}>
              <Typo classes="is-color-Black is-size-3">{title}</Typo>
            </div>
          )}
          <div className={`${styles.flipIcon}`} key={shortid.generate()}>
            <Button
              ariaLabel={title ? `${title} flip` : "flip button"}
              eventLink
              icon="flip"
            />
          </div>
        </div>
        <div
          className={`${styles.flipCardBack}`}
          onClick={() => setIsFlipped(false)}
        >
          <div className={`${styles.flipCardBackDetails}`}>
            {title && <Typo classes=" mb-2 is-size-3">{title}</Typo>}
            {updatedDescription && updatedDescription.string.length > 0 && (
              <Typo normal>
                {/* <RichText document={description} /> */}
                {updatedDescription.string}
                {updatedDescription.charLeftCount > 0 && "..."}
              </Typo>
            )}
            {flipCardLink && (
              <Typo normal>
                <RichText document={flipCardLink} />
              </Typo>
            )}
          </div>
          <div className={`${styles.flipIconBack}`} key={shortid.generate()}>
            <Button
              ariaLabel={title ? `${title} flip` : "button flip"}
              eventLink
              icon="flip"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaFlipCard;
