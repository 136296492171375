/* eslint-disable import/no-cycle */
import { FC } from "react";
import { IFeatureTextProps } from "../../blades/featureText/@types";
import ImageComponent from "../Image";
import Button, { ThemeType } from "../Button";
import RichText from "../RichText";
import SectionWrapper from "../SectionWrapper";
import Typo from "../Typo";
import styles from "./featureText.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";

const FeatureTextMain: FC<IFeatureTextProps> = (props) => {
  const { title, description, link, commonProperties } = props;
  return (
    <div
      className={`${styles.featureTextContainer} is-bgcolor-${commonProperties?.fields?.backgroundColor} has-text-centered is-flex is-flex-direction-column`}
    >
      <Typo h1>{title}</Typo>
      <Typo normal>
        <RichText document={description} />
      </Typo>
      {link && (
        <Button theme={link.fields.layout as ThemeType} link={link} key={0} />
      )}
    </div>
  );
};

const FeatureText: FC<IFeatureTextProps> = (props) => {
  const { isMobile } = useIsMobileViewport();

  return (
    <div className={`${styles.artistContainer}`}>
      {isMobile ? (
        <div>
          <ImageComponent
            image="/static/images/homePage/featureText/aboutus_triangle.png"
            width="145px"
            height="146px"
            classes={styles.triangleFilled}
          />
          <ImageComponent
            image="/static/images/homePage/featureText/aboutus_triangle_border.png"
            width="223px"
            height="223px"
            classes={styles.triangleBorder}
          />
          <ImageComponent
            image="/static/images/homePage/featureText/aboutus_lines.svg"
            width={isMobile ? "300px" : "557px"}
            height="170px"
            classes={styles.lines}
          />
          <FeatureTextMain {...props} />
        </div>
      ) : (
        <SectionWrapper>
          <ImageComponent
            image="/static/images/homePage/featureText/aboutus_triangle.png"
            width="145px"
            height="146px"
            classes={styles.triangleFilled}
          />
          <ImageComponent
            image="/static/images/homePage/featureText/aboutus_triangle_border.png"
            width="223px"
            height="223px"
            classes={styles.triangleBorder}
          />
          <ImageComponent
            image="/static/images/homePage/featureText/aboutus_lines.svg"
            width="557px"
            height="153px"
            classes={styles.lines}
          />
          <FeatureTextMain {...props} />
        </SectionWrapper>
      )}
    </div>
  );
};

export default FeatureText;
