import { IChallengeProps } from "../../models/Challenge/@types";
import { CType, Media } from "../../models/Contentful/types";
import { ISkillbuilderProps } from "../../models/SkillBuilder/@types";

export const SKILLBUILDER_WINNERS = "Skillbuilder Winners";
export const CHALLENGE_WINNERS = "Challenge Winners";

export const SKILLBUILDER_WINNER_SLUG = "skillbuilders";
export const CHALLENGE_WINNER_SLUG = "challenges";

export const WINNERS_MAPPING = {
  CHALLENGE_WINNERS: "Monthly Challenge Winners",
  SKILLBUILDER_WINNERS: "Daily Skillbuilder Winners",
};

export type WinnerCategory =
  | "Daily Skillbuilder Winners"
  | "Monthly Challenge Winners";
export interface IWinnerCardProps {
  teamName: string;
  winnerOf: CType<IChallengeProps> & CType<ISkillbuilderProps>;
  category: WinnerCategory;
  background?: string;
  submissionUrl?: string;
  rankIcon?: CType<Media>;
  award?: string;
}
