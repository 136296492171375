import { FC } from "react";
import { logger } from "../../utils";
import ThemeDefinition from "../../utils/Theme";
import SingleRow from "../../components/SingleRow";
import { CType } from "../../models/Contentful/types";
import { SingleRowContentProps } from "./@types";

const SingleRowContent: FC<CType<SingleRowContentProps>> = (props) => {
  try {
    const {
      fields: { ...data },
    } = props;
    const { backgroundColor, titleColor, descColor } = ThemeDefinition(
      data.theme,
    );
    return (
      <SingleRow {...{ titleColor, descColor, backgroundColor, ...data }} />
    );
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export default SingleRowContent;
