import React, { FC } from "react";
import clsx from "clsx";

import { Document } from "@contentful/rich-text-types";
import RichTextComponentGraphQL from "../../components/RichTextComponentGraphQL";
import { logger } from "../../utils";

type GraphQLDocument = {
  json: Document;
  links: any;
};

export interface IRichTextBladeProps {
  text: GraphQLDocument;
  isCenterAligned?: boolean;
}
const RichTextComponent: FC<IRichTextBladeProps> = ({
  text,
  isCenterAligned,
}) => {
  try {
    return (
      <div className={clsx({ "has-text-centered": isCenterAligned })}>
        <RichTextComponentGraphQL json={text.json} links={text.links} />
      </div>
    );
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export default RichTextComponent;
