/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-cycle */
import { FC } from "react";
import { CType } from "../../../models/Contentful/types";
import { IMediaCardsProps } from "../../../blades/multiColumnBlade/@types";
import ImageComponent from "../../Image";
import RichText from "../../RichText";
import SectionWrapper from "../../SectionWrapper";
import Typo from "../../Typo";
import VideoPlayer from "../../VideoPlayer";
import styles from "./mediaCardBlade.module.sass";

const MediaCardBlade: FC<CType<IMediaCardsProps>> = (props) => {
  const { fields } = props;
  const { title, description, videoUrl, media, videoHeight, videoWidth } =
    fields;
  // const [isActive, setIsActive] = useState(false);
  return (
    <SectionWrapper classes="has-text-centered">
      {title && <Typo h2>{title}</Typo>}
      <div className="mt-4 mb-5">
        {description && (
          <Typo medium classes="has-text-centered">
            <RichText document={description} classes="rich-text-container" />
          </Typo>
        )}
      </div>
      {!videoUrl ? (
        <div className={styles.imageComponent}>
          <ImageComponent image={media} width={500} height={400} />
        </div>
      ) : (
        <div className={`${styles.setPosition} `}>
          {/* <a
            onClick={() => setIsActive(true)}
            aria-hidden="true"
            className={styles.thumbnailContainer}
          >
            <SvgLib icon="playVideo" classes={styles.playIcon} />
            <ImageComponent
              image={thumbnail}
              aspectRatio="16 / 9"
              width="100%"
              height="auto"
              classes="mb-3"
            />
          </a> */}
          {/* <ModalWrapper isActive={isActive} onClose={() => setIsActive(false)}> */}
          <VideoPlayer
            videoUrl={videoUrl}
            videoHeight={videoHeight}
            videoWidth={videoWidth}
          />
          {/* </ModalWrapper> */}
        </div>
      )}
    </SectionWrapper>
  );
};

export default MediaCardBlade;
