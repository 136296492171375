/* eslint-disable no-useless-computed-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
import { FC, useState } from "react";
import clsx from "clsx";
import { TabItemProps, TabsBladeProps } from "../../blades/tabs/@types";
import Button, { ThemeType } from "../Button";
import {
  CLinkProps,
  CType,
  IExternalLinkProps,
} from "../../models/Contentful/types";
import Link from "../Link";
import ModalWrapper from "../ModalWrapper";
import SectionWrapper from "../SectionWrapper";
import SvgLib from "../SvgLib";
import Typo from "../Typo";
import VideoPlayer from "../VideoPlayer";
import Widgets from "../../blades/Widgets";
import styles from "./tabs.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";

const getPageLink = (
  tabItem: CType<TabItemProps>,
  baseUrl: string,
  entryId: string,
): CLinkProps => {
  return {
    sys: { contentType: { sys: { id: "internalLinks", type: "Link" } } },
    fields: {
      text: tabItem.fields.title,
      scrollPage: false,
      referencePage: {
        sys: { contentType: { sys: { id: "page", type: "Entry" } } },
        fields: {
          slug: `${baseUrl}/${tabItem.fields.slug}`,
          directory: "",
          entryId,
        },
      },
    },
  };
};
const TabsComponent: FC<TabsBladeProps> = (props) => {
  const { tabItems = [], links, isNested, tabUrl, currentNestedTab } = props;
  const { isMobile } = useIsMobileViewport();
  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  return (
    <div className={`${isMobile && " py-1"}`}>
      <SectionWrapper hasBodyMargin={false}>
        <div
          className={
            !isNested ? styles.tabsContainer : styles.tabContainerNested
          }
        >
          <div className={!isNested ? styles.tabs : styles.tabsNested}>
            {tabItems.map((tab) => {
              const pageLink = getPageLink(
                tab,
                tabUrl ?? "",
                tab.sys?.id ?? "",
              );
              return tab.fields.titleIcon ? (
                <div className={styles.iconsRoot} key={tab.fields.slug}>
                  <div className={styles.iconContainer}>
                    <div className={styles.icon}>
                      <Link {...pageLink}>
                        <SvgLib
                          icon={tab.fields.titleIcon}
                          classes={styles.icon}
                        />
                      </Link>
                    </div>
                    <div className={styles.titleContainer}>
                      <Button
                        ariaLabel={tab.fields.title}
                        eventLink
                        onlyText
                        heightLarge={!!isMobile}
                        link={pageLink}
                        // classes={`${tab.fields.isCurrent ? "selected" : ""}`}
                        classes={clsx({
                          ["selected"]:
                            tab.fields.slug === currentNestedTab?.fields.slug,
                        })}
                      >
                        {/* <div onClick={() => setSelectedTab(index)}> */}
                        <Typo h4 classes={styles.borderRight}>
                          {tab.fields.title}
                        </Typo>
                        {/* </div> */}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.tabContainer} key={tab.fields.slug}>
                  <Button
                    eventLink
                    onlyText
                    underline
                    link={getPageLink(tab, tabUrl ?? "", tab.sys?.id ?? "")}
                  >
                    {tab.fields.title}
                  </Button>
                </div>
              );
            })}
          </div>
          {links && (
            <>
              <Button
                theme={links[0].fields.layout as ThemeType}
                onClick={() => setIsModalActive(true)}
              >
                {links[0].fields.text}
              </Button>
              <ModalWrapper
                isActive={isModalActive}
                onClose={() => setIsModalActive(false)}
              >
                <VideoPlayer
                  videoUrl={(links[0].fields as IExternalLinkProps).url}
                  isActive={isModalActive}
                />
              </ModalWrapper>
            </>
          )}
        </div>
      </SectionWrapper>
      <SectionWrapper hasBodyMargin={!isMobile}>
        <div>
          <Widgets widget={currentNestedTab?.fields.widget} />
        </div>
      </SectionWrapper>
    </div>
  );
};

export default TabsComponent;
