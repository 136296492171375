/* eslint-disable import/no-cycle */
import React, { FC } from "react";
import { logger } from "../../utils";
import IFrameContainer from "../../components/IFrameContainer";
import { CType } from "../../models/Contentful/types";
import { EmbedIFrameProps } from "./@types";

const EmbedIFrame: FC<CType<EmbedIFrameProps>> = (props) => {
  try {
    const {
      fields: { ...data },
    } = props;
    return (
      <IFrameContainer
        src={data.sourceUrl}
        title={data.friendlyName}
        height={data?.height}
        width={data?.width}
      />
    );
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export default EmbedIFrame;
