import ChallengeList from "../../components/ChallengeList";
import { IContentListProps } from "../../components/ContentList/@types";
import EventList from "../../components/EventsList";
import SkillBuilderList from "../../components/SkillbuilderList";
import WinnerList from "../../components/WinnerList";
import { logger } from "../../utils";
import { CType } from "../../models/Contentful/types";

const GridBlade: React.FC<CType<IContentListProps>> = ({ fields }) => {
  try {
    if (fields.type === "Challenge") return <ChallengeList {...fields} />;
    if (fields.type === "Skillbuilder") return <SkillBuilderList {...fields} />;
    if (fields.type === "Winner") return <WinnerList {...fields} />;
    if (fields.type === "Event") return <EventList {...fields} />;
    return null;
  } catch (error) {
    logger.error(error);
    return null;
  }
};
export default GridBlade;
