/* eslint-disable prettier/prettier */
import React, { FC, useState } from "react";
import { IAccordionItemProps, IAccordionProps } from "./@types";

import AccordionItem from "./AccordionItem";
import { CType } from "../../models/Contentful/types";
import SectionWrapper from "../SectionWrapper";
import Typo from "../Typo";
import helpers from "../../utils/helpers";
import styles from "./accordion.module.sass";

const Accordion: FC<IAccordionProps> = (props) => {
  const { items, heading } = props;
  const [openItemId, setOpenItemId] = useState<string>();

  return (
    <SectionWrapper>
      <div>
        {heading && (
          <Typo
            h3
            classes={styles.accordionHeading}
            id={helpers.slugStringify(heading)}
          >
            {heading}
          </Typo>
        )}
        {items?.map((item: CType<IAccordionItemProps>) => {
          const {
            fields: { label, description, image },
            sys,
          } = item;
          return (
            <AccordionItem
              key={sys.id}
              isOpen={sys.id === openItemId}
              onClick={() => {
                if (sys.id === openItemId) setOpenItemId(undefined);
                else setOpenItemId(sys.id);
              }}
              label={label}
              description={description}
              image={image}
            />
          );
        })}
      </div>
    </SectionWrapper>
  );
};

export default Accordion;
