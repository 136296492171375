/* eslint-disable import/no-cycle */
import React from "react";
import { AvailableIcons } from "../SvgLib/@types";
import SvgLib from "../SvgLib";
import Typo from "../Typo";
import Widgets from "../../blades/Widgets";
import styles from "./tabscontent.module.sass";

export interface TabsContentProps {
  title: string;
  description?: string;
  icons?: AvailableIcons[];
  widget?: any[];
}

const TabsContent = (props: TabsContentProps) => {
  const { title, description, icons, widget } = props;

  return (
    <div className={styles.root}>
      <Typo h2 classes="mb-3">
        {title}
      </Typo>
      <div className={styles.description}>
        <Typo normal>{description}</Typo>
      </div>
      {icons && (
        <div className={styles.iconsRoot}>
          {icons.map((icon) => (
            <div className={styles.iconContainer}>
              <div className={styles.icon}>
                <SvgLib icon={icon} classes={styles.icon} />
              </div>
              <Typo h4>{icon}</Typo>
            </div>
          ))}
        </div>
      )}
      {widget && <Widgets widget={widget} />}
    </div>
  );
};

export default TabsContent;
