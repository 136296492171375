import React, { FC, useContext, useEffect, useState } from "react";

import AuthContext from "../../AppContext/AuthProvider";
import { CType } from "../../models/Contentful/types";
import ContentList from "../ContentList";
import FilterBar from "../ContentList/FilterBar";
import Header from "../ContentList/header";
import { IContentListProps } from "../ContentList/@types";
import { ISkillbuilderProps } from "../../models/SkillBuilder/@types";
import LoadMoreButton from "../ContentList/LoadMoreButton";
import ScrollButton from "../ScrollButton";
import SectionWrapper from "../SectionWrapper";
import Skillbuilder from "../Skillbuilder";
import { getSkillbuilderDetailsForUser } from "../../models/SkillBuilder/api";
import { getTagList } from "../../models/Contentful";
import styles from "./list.module.sass";
import useContentList from "../ContentList/useContentList";

const SORTING_OPTIONS: Record<string, string> = {
  Newest: "-fields.launchedDate", // "sys.updatedAt",
  Oldest: "fields.launchedDate", // "-sys.updatedAt",
  "A-Z": "fields.title",
};

type TSkillBuilderEntry = CType<ISkillbuilderProps>;
const SkillBuilderList: FC<IContentListProps> = (props) => {
  const { title, description } = props;
  const [industries, setIndustries] = useState<string[]>([]);
  const [skills, setSkills] = useState<string[]>([]);
  const [mutatedSkillbuilder, setMutatedSkillbuilder] = useState<
    TSkillBuilderEntry[]
  >([]);

  useEffect(() => {
    getTagList("industries").then((industries) => setIndustries(industries));
    getTagList("skills").then((skills) => setSkills(skills));
  }, []);

  const {
    loadMore,
    hasMore,
    applyFilter,
    list: skillBuilders,
    filter,
    total,
    currentPage,
  } = useContentList<ISkillbuilderProps>({
    contentType: "skillbuilder",
    filter: {
      // order: "sys.updatedAt",
      order: "-fields.launchedDate",
    },
  });
  const { appUser } = useContext(AuthContext);
  useEffect(() => {
    const mutateSkillbuilderData = async () => {
      const skillbuilderIdList = skillBuilders.map((item) => {
        return item?.sys?.id ?? item.fields.slug;
      });

      const newSkillbuilderList = await getSkillbuilderDetailsForUser(
        skillbuilderIdList,
        skillBuilders,
      );

      setMutatedSkillbuilder(newSkillbuilderList);
    };
    appUser && mutateSkillbuilderData();
  }, [appUser, skillBuilders]);

  return (
    <SectionWrapper hasBodyMargin={false}>
      <Header title={title} description={description} />
      <FilterBar
        containerClass={styles.containerRoot}
        primary={[
          {
            key: "industryList.fields.title",
            list: industries,
            label: "All Industries",
          },
          { key: "skills.fields.title", list: skills, label: "All Skills" },
        ]}
        secondary={[
          {
            key: "order",
            list: ["Newest", "Oldest", "A-Z"],
            label: "Newest",
            hasDropDownIcon: true,
          },
        ]}
        onChange={(key: string, value?: string) => {
          let filterValue = value ?? "";
          let filterKey = "";
          if (key === "order") {
            filterValue = SORTING_OPTIONS[value ?? "Newest"];
            filterKey = key;
          } else filterKey = `fields.${key}`;
          applyFilter({
            ...filter,
            [filterKey]: filterValue,
            "fields.industryList.sys.contentType.sys.id": "industries",
            "fields.skills.sys.contentType.sys.id": "skills",
          });
        }}
      />
      <SectionWrapper classes="pt-6">
        <ContentList<TSkillBuilderEntry>
          items={
            mutatedSkillbuilder?.length ? mutatedSkillbuilder : skillBuilders
          }
          keyExtractor={(challenge: TSkillBuilderEntry) =>
            (challenge.sys?.id ?? "") as string
          }
          renderItem={(item: TSkillBuilderEntry) => <Skillbuilder {...item} />}
        />
      </SectionWrapper>
      <SectionWrapper classes="pb-6">
        <LoadMoreButton
          hasMore={!!skillBuilders?.length && hasMore}
          loadMore={loadMore}
          meta={{
            total,
            fetchedCount: skillBuilders.length,
            pageSize: 4,
            currentPage,
          }}
        />
      </SectionWrapper>
      <ScrollButton />
    </SectionWrapper>
  );
};

export default SkillBuilderList;
